import request from "../api/config";

const getJobByNumberCode = async (numberCode) => {
    try {
        const response = await request.get(`Job/get-by-number-code/${numberCode}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getAllJob = async () => {
    try {
        const response = await request.get(`Job/get-all`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateJob = async (jobId, formdata) => {
    try {
        const response = await request.put(`Job/update/${jobId}`, formdata);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}


export const jobService = {
    getJobByNumberCode, getAllJob, updateJob
}