import classNames from "classnames/bind";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { faBarsProgress, faBook, faChartColumn, faCircleCheck, faFileInvoiceDollar, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";


import styles from './Home.module.scss';
import LineChart from "../../../components/LineChart";
import {pageService, bookService, userService} from '../../../services';
import Layout from "../../../components/Layout";

const cx = classNames.bind(styles);

function Home() {

    const [lineChartData, setLineChartData] = useState(null);
    const [totalSpending, setTotalSpending] = useState(0);
    const [totalCompleted, setTotalCompleted] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [inProgressBooks, setInProgressBooks] = useState([]);
    const [recentBooks, setRecentBooks] = useState([]);
    const [bookAmount, setBookAmount] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [completedBooks, setCompletedBooks] = useState([]);


    const user = useSelector(state => state.auth.login?.currentUser);


    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const fetchInProgressBook = async () => {
            const data = await bookService.getInProgress();
            if (data != null) {
                setInProgressBooks(data.books);
            }
        }

        const fetchCompletedBooks = async () => {
            const data = await bookService.getCompletedBooks();
            if(data != null){
                setCompletedBooks(data.books);
                setTotalCompleted(data.total);
            }
            // setCompletedBooks(data.books);
            // setTotalCompleted(data.total);
        }

        const fetchTotalSpendingData = async () => {
            const data = await pageService.getTotalSpending(false, false);
            if(data != null){
                setTotalSpending(data.total);
            }
            // setTotalSpending(data.total);
        }

        const fetchTotalUser = async () => {
            const data = await userService.getTotalUser();
            if(data != null){
                setTotalUser(data.total - 1);
            }
            // setTotalUser(data.total - 1);
        }

        const fetchRecentBooks = async () => {
            const data = await bookService.getAllBook(1, 5);
            setRecentBooks(data.books);
        }

        const fetchBookAmount = async () => {
            const data = await bookService.getAmount();
            if(data != null){
                setBookAmount(data.amount);
            }
            // setBookAmount(data.amount);
        }

        fetchCompletedBooks();
        fetchTotalSpendingData();
        fetchInProgressBook();
        fetchTotalUser();
        // fetchRecentBooks();
        fetchBookAmount();
    }, []);

    useEffect(() => {

        const getSpendingByWeek = async () => {
            const total = await pageService.getTotalSpendingByWeek();
            if (total != null) {
                const lineData = {
                    labels: ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"],
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "#1b69dd",
                            borderColor: "#1b69dd",
                            data: total.result,
                        },
                    ],
                };
                setLineChartData(lineData);
            }
            else {
                const labels = ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"];
                const lineData = {
                    labels: labels,
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "#1b69dd",
                            borderColor: "#1b69dd",
                            data: [],
                        },
                    ],
                };
                setLineChartData(lineData);
            }
        }

        const getSpendingByMonth = async () => {
            const total = await pageService.getTotalSpendingByMonth(user.id);
            if (total != null) {
                const lineData = {
                    labels: ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"],
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "#1b69dd",
                            borderColor: "#1b69dd",
                            data: total.result,
                        },
                    ],
                };
                setLineChartData(lineData);
            }
            else {
                const labels = ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"];
                const lineData = {
                    labels: labels,
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "#1b69dd",
                            borderColor: "#1b69dd",
                            data: [],
                        },
                    ],
                };
                setLineChartData(lineData);
            }
        }

        const initial = async () => {
            if (selectedOption === "month") {
                getSpendingByMonth();
            }
            else {
                getSpendingByWeek();
            }
        };

        initial();
    }, [selectedOption, user]);

    return (
        <Layout>
            <p className="m-0 fs-3 fw-bolder" style={{ fontFamily: "Inter-Bold", padding: "15px 20px" }}>Dashboard</p>
            <div className={cx("wrapper")}>
                <div className="row container-fluid align-items-center g-4 mb-3">
                    <div className="col-12 col-md-auto me-5">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ fontSize: "28px", color: "#4bab4b" }} />
                            <div className="ms-3">
                                <h4 style={{ fontSize: "1.25rem", }} className="mb-0 fw-bolder">{totalSpending.toLocaleString('vn')}</h4>
                                <p className="text-800 fs--1 mb-0" style={{ color: "rgb(62, 70, 91)", fontSize: "12px" }}>Tổng số tiền</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-auto me-5">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faUserGroup} style={{ fontSize: "28px", color: "rgb(229, 120, 11)" }} />
                            <div className="ms-3">
                                <h4 style={{ fontSize: "1.25rem", }} className="mb-0 fw-bolder">{totalUser}</h4>
                                <p className="text-800 fs--1 mb-0" style={{ color: "rgb(62, 70, 91)", fontSize: "12px" }}>Cộng tác viên</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-auto me-5">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: "28px", color: "rgb(144, 214, 127)" }} />
                            <div className="ms-3">
                                <h4 style={{ fontSize: "1.25rem", }} className="mb-0 fw-bolder">{totalCompleted.toLocaleString('vn')}</h4>
                                <p className="text-800 fs--1 mb-0" style={{ color: "rgb(62, 70, 91)", fontSize: "12px" }}>Sách đã hoàn tất</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-auto">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faBook} style={{ fontSize: "28px", color: "rgb(244, 130, 112)" }} />
                            <div className="ms-3">
                                <h4 style={{ fontSize: "1.25rem", }} className="mb-0 fw-bolder">{bookAmount}</h4>
                                <p className="text-800 fs--1 mb-0" style={{ color: "rgb(62, 70, 91)", fontSize: "12px" }}>Tổng số sách</p>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ color: "rgb(180 170 170)" }} />

                <div className="row">
                    <div className="col-6" >
                        <div className="" style={{ backgroundColor: "white", borderRadius: "6px", height: "50vh" }}>
                            <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                                <FontAwesomeIcon icon={faChartColumn} />&ensp;
                                Thống kê
                            </p>
                            <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                            <div className="container mt-3">
                                <div className="mb-3 text-end">
                                    <select id="select-box" className="select-box form-select d-inline-block w-25" style={{ padding: "5px 10px", border: "1px solid #ccc", borderRadius: "6px", color: "#2b2b2b", outline: "none" }} onChange={handleSelectChange}>
                                        <option value="week">Tuần</option>
                                        <option value="month">Tháng</option>
                                    </select>
                                </div>
                                {lineChartData && <LineChart data={lineChartData} />}
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="" style={{ backgroundColor: "white", borderRadius: "6px", height: "50vh", overflowX: "scroll" }}>
                            <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                                <FontAwesomeIcon icon={faBarsProgress} />&ensp;
                                Sách đã hoàn tất
                            </p>
                            <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                            {totalCompleted == 0 && <p className="text-center mt-5" style={{color: "#969ba0"}}>Hiện chưa có sách nào hoàn tất</p>}
                            <div style={{padding: "10px 0" }}>
                                {completedBooks.map((book, index) => (
                                    <div key={book.id} className={cx("book-item")}>
                                        {/* <p  className={cx("stt") + " m-0"} ></p> */}
                                        <p className={cx("name") + " m-0"}>{index + 1}.&ensp;{book.title}</p>
                                    </div>
                                ))}
                            </div>
                        </div>

                    </div>
                </div>


                <div className="current-books mt-3" style={{ backgroundColor: "white" }}>
                    <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                        <FontAwesomeIcon icon={faBook} />&ensp;
                        Sách đang thực hiện
                    </p>
                    <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                    {inProgressBooks && (
                        <TableContainer component={Paper}>
                            <style>
                                {`
                            .MuiTableCell-head {
                                font-weight: 600;
                            }
                            `}
                            </style>
                            <Table sx={{ minWidth: 500 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Mã sách</TableCell>
                                        <TableCell align="left">Tiêu đề</TableCell>
                                        <TableCell align="left">Tác giả</TableCell>
                                        <TableCell align="left">NXB</TableCell>
                                        <TableCell align="center">Năm xuất bản</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inProgressBooks.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.libCode}
                                            </TableCell>
                                            <TableCell align="left">{row.title}</TableCell>
                                            <TableCell align="left">{row.author}</TableCell>
                                            <TableCell align="left">{row.publisherName}</TableCell>
                                            <TableCell align="center">{row.publishYear}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </div>
            </div >
        </Layout >
    )
}
export { Home as AdminHome };
export default Home;