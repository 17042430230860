import { Step } from "@mui/material";
import { Stepper } from "@mui/material";
import { Box } from "@mui/material";
import { StepButton } from "@mui/material";
import { Button } from "@mui/material";
import { Typography } from "@mui/material";
import { Fragment, useState, useEffect } from "react";
import classNames from "classnames/bind";



import StepItem1 from "../StepItem1";
import StepItem2 from "../StepItem2";
import StepItem3 from "../StepItem3";
import styles from './StepComponent.module.scss';

const cx = classNames.bind(styles);

function StepComponent({ type, page, pdfData, closeModal }) {
    const steps = ['Bản scan', 'Bản text', 'Bản audio'];
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [maxStep, setMaxStep] = useState(0);

    useEffect(() => {
        if (type == 3) {
            setActiveStep(1);
            setMaxStep(1);
        }
        else if (type == 4) {
            setActiveStep(1);
            setMaxStep(1);
        }
        else if (type == 5) {
            setActiveStep(2);
            setMaxStep(2);
        }
    }, [type]);

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep == totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() == totalSteps();
    };

    const handleClose = () => {
        closeModal();
    }

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ?
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        if (step > maxStep) return;
        setActiveStep(step);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    return (
        <div className={cx("wrapper")} style={{ padding: "20px 0" }}>
            <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep}>
                    {steps.map((label, index) => (
                        <Step key={label} completed={completed[index]}>
                            <StepButton color="inherit" onClick={handleStep(index)}>
                                {label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
                <div>
                    {allStepsCompleted() ? (
                        <Fragment>
                            <Typography sx={{ mt: 2, mb: 1 }}>
                                All steps completed - you&apos;re finished
                            </Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleReset}>Reset</Button>
                            </Box>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                                {activeStep == 0 && (<StepItem1 pageIndex={page.pageIndex} pdfData={pdfData} url={page.url1} bookId={page.bookId} pageId={page.id} page={page} />)}
                                {activeStep == 1 && (<StepItem2 page={page} pdfData={pdfData} />)}
                                {activeStep == 2 && (<StepItem3 />)}
                            </Typography>
                            <div>
                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                    <Button
                                        color="inherit"
                                        onClick={handleClose}
                                        sx={{ mr: 1 }}
                                    >
                                        Đóng
                                    </Button>

                                    <Button
                                        color="inherit"
                                        disabled={activeStep == 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Quay lại
                                    </Button>
                                    <Box sx={{ flex: '1 1 auto' }} />
                                    <Button onClick={handleNext} sx={{ mr: 1 }} disabled={activeStep >= maxStep}>
                                        Bước tiếp theo
                                    </Button>
                                </Box>
                            </div>
                        </Fragment>
                    )}
                </div>
            </Box>
        </div>

    );
}

export default StepComponent;