import classNames from "classnames/bind";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import styles from './PasswordInfo.module.scss';
import Spinner from "../Spinner";
import { userService } from "../../services";

const cx = classNames.bind(styles);

function PasswordInfo() {

    const user = useSelector(state => state.auth.login?.currentUser);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    const handleUpdate = async () => {
        if(newPassword !== confirmNewPassword){
            toast.warning('Mật khẩu không khớp', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            return;
        }

        setIsLoading(true);
        const formData = {
            userId: user.id,
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        };

        const data = await userService.changePassword(formData);
        if(data != null){
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            setOldPassword("");
            setNewPassword("");
            setConfirmNewPassword("");
        }
        else{
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }
    
    return ( 
        <div className="wrapper container mt-5" style={{backgroundColor: "white", width: "80%", padding: "50px 0", borderRadius: "6px"}}>
            {isLoading && <Spinner/>}
            <div className={cx('add-form') + ' container-fluid w-50'}>
                <form>
                    <div className="row">
                        <div className='col-12'>
                            <label htmlFor="oldPassword">Mật khẩu</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="password" id="oldPassword" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} />
                        </div>
                        <div className='col-12 mt-4'>
                            <label htmlFor="newPassword">Mật khẩu mới</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="password" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                        </div>
                        <div className='col-12 mt-4'>
                            <label htmlFor="confirmNewPassword">Xác nhận mật khẩu</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="password" id="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setConfirmNewPassword(e.target.value)} />
                        </div>
                    </div>
                    <p className={cx('add-new-btn') + ' mt-5 btn btn-primary'} onClick={handleUpdate}>Cập nhật</p>
                </form>
            </div>
        </div>
     );
}

export default PasswordInfo;