import classNames from "classnames/bind";
import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import styles from './BookInfoItem.module.scss';
import { bookService, imageService } from "../../services";

const cx = classNames.bind(styles);

function BookInfoItem() {
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const { id } = useParams();

  const [bookCover, setBookCover] = useState(null);
  const imgUrl = process.env.REACT_APP_API_URL + "image/";

  const [book, setBook] = useState({});

  useEffect(() => {
    const getBook = async () => {
      const data = await bookService.getBookById(id);
      if (data != null) setBook(data.book);
    }

    getBook();
  }, [id])

  useEffect(() => {
    const updateData = () => {
      if (book.bookCoverUrl) setBookCover(imgUrl + book.bookCoverUrl);
    }

    updateData();
  }, [book, imgUrl])

  const handleFileUpload = async (event) => {

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    };

    reader.readAsArrayBuffer(file);
    fileInputRef.current.value = null;
  };

  const handleChangeBookCover = async () => {
    const formData = {
      file: selectedFile
    };

    const data = await imageService.uploadBookCover(book.id, formData);
    if (data.url != null) {
      const bookFormData = {
        bookCoverUrl: data.url
      };
      const result = await bookService.updateBook(book.id, bookFormData);
      if (result != null) {
        setSelectedFile(null);
        setPreviewUrl(null);
        book.bookCoverUrl = data.url;
        setBookCover(imgUrl + book.bookCoverUrl);
        toast.success('Thành công', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
        return;
      }
    }
    toast.error('Lỗi khi cập nhật ảnh', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      draggable: true
    });

  }

  return (
    <div className={cx('') + ' container mt-2 w-100'}>
      <h5 style={{ fontFamily: "Inter-ExtraBold", color: "#757e86" }}>Thông tin chung</h5>
      <div className="d-flex ms-5 mb-5 mt-4">
        <div className="img text-center">
          {previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={previewUrl} alt="Book Cover" />}
          {!previewUrl && bookCover && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={bookCover} alt="Book Cover" />}
          {!previewUrl && !bookCover && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={imgUrl + "Shared/empty.png"} alt="Book Cover" />}
          <br />
          {/* <span className="mt-2 mb-3 d-block">Ảnh bìa</span> */}
          <input type="file" accept=".png, .jpg, .jpeg" id="fileInput" className={cx("file-input")} ref={fileInputRef} onChange={handleFileUpload} />
          <label htmlFor="fileInput" className={cx("file-label") + " mt-3"}>
            <p class="btn btn-outline-primary" type="button" style={{ backgroundColor: "white", color: "blue", border: "blue solid 1px", borderRadius: "18px", fontSize: "14px" }}>Cập nhật ảnh bìa</p>
          </label>
        </div>
        <div className="ms-3">
          <h5 className="fw-bolder mt-3">{book.title}</h5>
          {book.subTitle && <p className="fst-italic m-0">({book.subTitle})</p>}
          <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Mã sách: {book.libCode}</p>
          <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Tác giả: {book.author}</p>
          <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">NXB: {book.publisherName}</p>
          <br />
        </div>
      </div>

      <div className="more-detail container mt-t">
        <h5 style={{ fontFamily: "Inter-ExtraBold", color: "#757e86" }}>Thông tin thêm</h5>
        <div className="row ms-5 mt-4">
          <p className="col-6">Thể loại: {book.genre}</p>
          {book.translator && <p>Dịch giả: {book.translator}</p>}
          {book.volume && <p>Tập: {book.volume}</p>}
          <p className="col-6">Lần tái bản: {book.reIssue}</p>
          <p className="col-6">Năm xuất bản: {book.publishYear}</p>
          <p className="col-6">Số trang: {book.totalPage}</p>
        </div>

      </div>

      {selectedFile && <button onClick={handleChangeBookCover} className="btn btn-primary ps-4 pe-4 mt-4 float-end">Lưu</button>}



    </div>
  );
}

export default BookInfoItem;