import request from "../api/config";

const getHistory = async (workerId, pageNumber, pageSize) => {
    try {
        const response = await request.get(`PageHistory/get-all/${workerId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const searchPageHistories = async (userId, status, pageNumber, pageSize) => {
    try {
        const response = await request.get(`PageHistory/search/${userId}?status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        return null;
    }
}


export const pageHistoryService = {
    getHistory, searchPageHistories
}