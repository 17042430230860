import classNames from "classnames/bind";

import styles from './BookDetail.module.scss';
import Layout from "../../components/Layout";
import BookInfoItem from "../../components/BookInfoItem";
import BookDetailItem from "../../components/BookDetailItem";
import Tab from "../../components/Tab";

import { faAdd, faCircleInfo, faFileUpload, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect } from "react";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { useParams, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import Modal from "../../components/Modal";
import StepComponent from "../../components/StepComponent";
import RenderPDF from "../../components/RenderPDF";
import Spinner from "../../components/Spinner";
import { pageService, bookService, pdfService } from "../../services";
import StepItem1 from "../../components/StepItem1";
const cx = classNames.bind(styles);

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const pageSize = 12;


function BookDetail() {
    let navigate  = useNavigate();

    // const items = [
    //     {
    //         key: 1,
    //         label: "Thông tin chung",
    //         children: <BookInfoItem/>
    //     },
    //     {
    //         key: 2,
    //         label: "Chi tiết",
    //         children: <BookDetailItem/>
    //     },
    // ];

    const user = useSelector(state => state.auth.login?.currentUser);
    const [modalOpen, setModalOpen] = useState(false);
    const [pageModalOpen, setPageModalOpen] = useState(false);
    const [newPageModal, setNewPageModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedPage, setSelectedPage] = useState(0);
    const [pageList, setPageList] = useState([]);
    const [totalPaginationPage, setTotalPaginationPage] = useState(1);
    const [pdfList, setPdfList] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [pageAddedNumber, setPageAddedNumber] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    const { id, type } = useParams();

    useEffect(() => {
        const getBook = async () => {
            try {
                const data = await bookService.getBookById(id);
                if (data != null) {
                    // setBook(data.book);
                    setTotalPaginationPage(Math.ceil(data.book.totalPage / 12));
                    setTotalPage(data.book.totalPage);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getBook();

    }, [id]);

    useEffect(() => {
        const fetchAPI = async () => {
            setIsLoading(true);
            const pdfs = [];
            const data = await pageService.getPages(id, pageNumber, pageSize);

            for (let i = 0; i < data.length; i++) {
                const fileName = `Page_${data[i].pageIndex}`;
                const pdf_item = await pdfService.getPDF(id, fileName);
                if (pdf_item != null) pdfs.push(pdf_item);

            }
            const newPageList = [];
            for (let i = 12 * (pageNumber - 1); i < pageNumber * 12; i++) {
                if (totalPage && i >= totalPage) {
                    break;
                }
                const foundPage = data.find((page) => page.pageIndex == i + 1);
                if (!foundPage) {
                    const item = {
                        url1: null,
                        pageIndex: i + 1,
                        currentStatus: 1,
                        bookId: id
                    }
                    newPageList.push(item);
                }
                else newPageList.push(foundPage);
            }
            setPageList(newPageList);
            setPdfList(pdfs);
            setIsLoading(false);
        }

        if (totalPage > 0) fetchAPI();
    }, [pageNumber, totalPage, id]);

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected + 1);
    };

    const openPageModal = (page) => {
        setSelectedPage(page);
        setPageModalOpen(true);
        const crrIndex = pageSize - ((pageSize * pageNumber) - page.pageIndex) - 1;
        setCurrentPageIndex(crrIndex);
    }

    const closePageModal = () => {
        setPageModalOpen(false);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const closeNewPageModal = () => {
        setNewPageModal(false);
    }

    const onOK = async () => {
        if (selectedImageList.length == 0) { closeModal(); return; }
        setIsLoading(true);
        const formData = new FormData();
        let pageNums = [];
        for (const file of selectedImageList) {
            formData.append('files', file);
            const fileName = file.name;
            const num = fileName.split('_')[1].split('.')[0];
            if (fileName.split("_")[0] == 'Trang' && !isNaN(num)) {
                if (pageNums.includes(num)) {
                    toast.warning('Trang bị trùng tên', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true
                    });
                    setIsLoading(false);
                    return;
                }
                pageNums.push(num);
            }
            else {
                toast.warning('Vui lòng đặt tên theo đúng quy tắc', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                setIsLoading(false);
                return;
            }
        }
        try {
            const data = await pageService.addListPageImage(id, user.id, formData);
            if (data != null) {
                toast.success('Thực hiện thành công', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                window.location.reload();
            }
        } catch (error) {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }
    const fileInputRef = useRef(null);

    const handleAddPage = async () => {
        const data = await pageService.addNewPage(id, pageAddedNumber);
        if (data != null) {
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
        else {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
    }

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        setSelectedImageList(files);
    };

    return (
        <Layout>
            <div className={cx("wrapper")}>
                {modalOpen && (
                    <Modal title="Tải lên nhiều tệp" isOpen={true} onClose={closeModal} onOK={onOK}>
                        <div className={cx("modal-container")}>
                            {/* <p className="text-center fw-bold">Tải lên bản scan</p> */}
                            {selectedImageList.length != 0 && (<span className="">Danh sách các tệp được chọn: </span>)}
                            {Array.from(selectedImageList).map((file, index) => (
                                <span key={index}> {index == 0 ? file.name : "," + file.name} </span>
                            ))}
                            <div className={cx("upload-space") + " d-flex justify-content-center"}>
                                <input
                                    multiple="multiple"
                                    type="file"
                                    accept=".jpg, .jpeg, .png"
                                    id="fileInput"
                                    className={cx("file-input")}
                                    ref={fileInputRef}
                                    onChange={handleFileUpload}
                                />
                                <label htmlFor="fileInput" className={cx("file-label")}>
                                    <FontAwesomeIcon icon={faUpload} />
                                    <span className={cx("button-text") + " ms-1"}>Tải lên</span>
                                </label>
                            </div>
                            <i className="ms-4 mt-3 d-block">Tên của tệp cần được đặt theo cú pháp: Trang_{"<số trang>"}.</i>
                            <i className="ms-4 mt-2">Ví dụ: Trang_1, Trang_2</i>
                        </div>
                    </Modal>
                )}

                {pageModalOpen && (
                    <Modal width="65%" isOpen={true} onClose={closePageModal} onOK={() => { }} OKButton={false} CancelButton={true} title="Bản scan">
                        {/* <StepComponent closeModal={closePageModal} type={type} page={selectedPage} pdfData={pdfList[currentPageIndex]} /> */}
                        <StepItem1 page={selectedPage} url={selectedPage.url1} pdfData={pdfList[currentPageIndex]}/>
                    </Modal>
                )}

                {newPageModal && (
                    <Modal width="40%" title="Thêm mới trang" isOpen={true} onClose={closeNewPageModal} onOK={() => { }} OKButton={false}>
                        {/* <h5>Thêm mới trang</h5> */}
                        {/* <hr /> */}
                        <div className="container">
                            <div className="info">
                                <label className="mb-1" htmlFor="page-num" style={{ fontSize: "14px", fontWeight: "bold", margin: "0" }}>Số trang</label>
                                <br />
                                <input style={{ border: "none", borderRadius: "3px", backgroundColor: "#ECF3FA", height: "38px", width: "300px", padding: "0 10px" }} onChange={(e) => setPageAddedNumber(e.target.value)} type="number" name="" id="" />
                            </div>

                            <div className="text-start mt-3">
                                <button className={cx("button-6")} onClick={handleAddPage}>
                                    Thêm
                                </button>
                            </div>

                        </div>
                    </Modal>
                )}

                {infoModalOpen && (<Modal title="Thông tin sách" width="60%" isOpen={true} onClose={() => setInfoModalOpen(false)} OKButton={false}>
                    <BookInfoItem/>
                </Modal>)}

                {isLoading && (<Spinner />)}
                <div className="container-fluid">
                    {/* <div style={{ borderRadius: "8px", padding: "12px", width: "100%" }}>
                        // <h5 style={{ fontFamily: "Inter-Medium" }}>Chi tiết sách</h5>
                        <div className="d-flex flex-row-reverse" style={{ borderRadius: "8px" }}>
                            <button onClick={() => setModalOpen(true)} className="btn btn-primary ms-2">
                                <FontAwesomeIcon className="me-2" icon={faFileUpload} />
                                <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Tải lên trang</span>
                            </button>
                            <button onClick={() => setNewPageModal(true)} className="btn btn-success ms-2">
                                <FontAwesomeIcon className="me-2" icon={faAdd} />
                                <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Thêm trang</span>
                            </button>
                            <button type="button" class="btn btn-info ms-2 text-light">
                                <FontAwesomeIcon className="me-2" icon={faCircleInfo} />
                                <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Thông tin sách</span>
                            </button>
                        </div>
                    </div> */}

                    <div className={cx("pages-container") + " pages row mt-3 m-auto"}>
                        <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                            Chi tiết sách
                        </p>
                        <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                        <div className="d-flex justify-content-between align-items-center p-3">
                            <div className="" style={{ borderRadius: "8px", width: "100%" }}>
                                <div className="d-flex flex-row-reverse" style={{ borderRadius: "8px" }}>
                                    {type == 1 && <button onClick={() => setModalOpen(true)} className="btn btn-primary ms-2">
                                        <FontAwesomeIcon className="me-2" icon={faFileUpload} />
                                        <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Tải lên trang</span>
                                    </button>}
                                    <button onClick={() => setNewPageModal(true)} className="btn btn-success ms-2">
                                        <FontAwesomeIcon className="me-2" icon={faAdd} />
                                        <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Thêm trang</span>
                                    </button>
                                    <button onClick={() => setInfoModalOpen(true)} type="button" class="btn btn-info ms-2 text-light">
                                        <FontAwesomeIcon className="me-2" icon={faCircleInfo} />
                                        <span style={{ fontSize: "14px", fontFamily: "Inter-Light" }}>Thông tin sách</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                        <div className={cx("book-container") + " row m-auto"}>
                            {pdfList.length > 0 && (
                                pageList.map((page, index) => (
                                    page.currentStatus == type ? (
                                        <div className={cx("page") + " col-md-2 text-center mt-4"} onClick={ () => {if(type == 1 || type == 2) openPageModal(page); else {navigate(`/page-detail/${page.bookId}/${page.pageIndex}/${page.currentStatus}`)} }}>
                                        {/* <div className={cx("page") + " col-md-2 text-center mt-4"} onClick={() => openPageModal(page)}>  */}
                                            <div className={cx("page-img-container")}>
                                                <div className={cx("page-pdf")}>
                                                    {page.style != 0 && (
                                                        <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                            <span>Trang trống</span>
                                                        </div>
                                                    )}
                                                    {page.style == 0 && page.url1 && page.url1.split(".").at(-1) != "pdf" && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                    {page.style == 0 && (!page.url1 || page.url1.split(".").at(-1) == "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />}
                                                </div>
                                                <div className={cx("overlay")}>
                                                </div>
                                            </div>
                                            <p className={cx("page-num")}>{"Trang " + page.pageIndex}</p>
                                            {page.currentStatus == 1 && <p className={cx("page-status")}>Chưa scan</p>}
                                            {page.currentStatus == 2 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            {page.currentStatus == 3 && <p className={cx("page-status")}>Thiết lập bản text</p>}
                                            {page.currentStatus == 4 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            {page.currentStatus == 5 && <p className={cx("page-status")}>Thiết lập audio</p>}
                                            {page.currentStatus == 6 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        </div>)
                                        :
                                        (
                                            <div className={cx("page") + " col-md-2 text-center mt-4"} style={{ pointerEvents: 'none' }} onClick={() => openPageModal(page)}>
                                                <div className={cx("page-img-container")}>

                                                    <div className={cx("page-pdf")}>
                                                        {page.style != 0 && (
                                                            <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                                <span>Trang trống</span>
                                                            </div>
                                                        )}
                                                        {page.style == 0 && page.url1 && page.url1.split(".").at(-1) != "pdf" && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                        {page.style == 0 && (!page.url1 || page.url1.split(".").at(-1) == "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />}
                                                    </div>
                                                    <div className={cx("overlay")}>
                                                    </div>
                                                </div>
                                                <p className={cx("page-num")} style={{ color: "#ccc" }} >Trang {page.pageIndex}</p>
                                                {page.currentStatus == 1 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chưa scan</p>}
                                                {page.currentStatus == 2 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                                {page.currentStatus == 3 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập bản text</p>}
                                                {page.currentStatus == 4 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                                {page.currentStatus == 5 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập audio</p>}
                                                {page.currentStatus == 6 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            </div>
                                        )
                                ))
                            )}

                            {pdfList.length == 0 && (
                                pageList.map((page, index) => (
                                    page.currentStatus == type ? (
                                        // <div className={cx("page") + " col-md-2 text-center mt-4"} onClick={() => openPageModal(page)}>
                                        <div className={cx("page") + " col-md-2 text-center mt-4"} onClick={ () => {if(type == 1 || type == 2) openPageModal(page); else {navigate(`/page-detail/${page.bookId}/${page.pageIndex}/${page.currentStatus}`)} }}>
                                            <div className={cx("page-img-container")}>
                                                <div className={cx("page-pdf")}>
                                                    {(page.style != 0 || (page.style == 0 && !page.url1)) && (
                                                        <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                            <span>Trang trống</span>
                                                        </div>
                                                    )}
                                                    {page.style == 0 && page.url1 && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                    {/* {page.style == 0 && !page.url1 && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />} */}
                                                </div>
                                                <div className={cx("overlay")}>
                                                </div>
                                            </div>
                                            <p className={cx("page-num")}>{"Trang " + page.pageIndex}</p>
                                            {page.currentStatus == 1 && <p className={cx("page-status")}>Chưa scan</p>}
                                            {page.currentStatus == 2 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            {page.currentStatus == 3 && <p className={cx("page-status")}>Thiết lập bản text</p>}
                                            {page.currentStatus == 4 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            {page.currentStatus == 5 && <p className={cx("page-status")}>Thiết lập audio</p>}
                                            {page.currentStatus == 6 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        </div>)
                                        :
                                        (
                                            <div className={cx("page") + " col-md-2 text-center mt-4"} style={{ pointerEvents: 'none' }} onClick={() => openPageModal(page)}>
                                                <div className={cx("page-img-container")}>

                                                    <div className={cx("page-pdf")}>
                                                        {(page.style != 0 || (page.style == 0 && !page.url1)) && (
                                                            <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                                <span>Trang trống</span>
                                                            </div>
                                                        )}
                                                        {page.style == 0 && page.url1 && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                        {/* {page.style == 0 && !page.url1 && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />} */}
                                                    </div>
                                                    <div className={cx("overlay")}>
                                                    </div>
                                                </div>
                                                <p className={cx("page-num")} style={{ color: "#ccc" }} >Trang {page.pageIndex}</p>
                                                {page.currentStatus == 1 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chưa scan</p>}
                                                {page.currentStatus == 2 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                                {page.currentStatus == 3 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập bản text</p>}
                                                {page.currentStatus == 4 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                                {page.currentStatus == 5 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập audio</p>}
                                                {page.currentStatus == 6 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                            </div>
                                        )
                                ))
                            )}

                        </div>
                        {pageList.length > 0 && (
                            <div className={cx("pagination-container") + " page mt-4"}>
                                <style>
                                    {`.page-link{
                                        color: #a39d9d !important; 
                                        border: none;
                                        }
                                        .active>.page-link, .page-link.active{
                                            color: white !important;
                                            background-color: var(--bs-pagination-active-bg);
                                            border: 1.2px solid var(--bs-pagination-active-bg);
                                            border-radius: 50px;
                                        }
                                        .disabled>.page-link, .page-link.disabled{
                                            color: #dddddd !important;
                                            background-color: white;
                                        }
                                        .page-link {
                                            padding: 4px 11px !important;
                                        }

                                    `}
                                </style>
                                <ReactPaginate
                                    nextLabel=">"
                                    onPageChange={handlePageChange}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={1}
                                    pageCount={totalPaginationPage}
                                    previousLabel="<"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        )}

                    </div>

                </div>
            </div>
        </Layout>
    );
}

export default BookDetail;