import classNames from "classnames/bind";
import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import styles from './PersonalInfo.module.scss';
import { updateUserState } from "../../api/request";
import { userService } from "../../services";
import Spinner from "../Spinner";

const cx = classNames.bind(styles);

function PersonalInfo() {
    const user = useSelector(state => state.auth.login?.currentUser);

    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [nationalId, setNationalId] = useState('');
    const [address, setAddress] = useState('');
    const [birthDay, setBirthDay] = useState('')
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(user){
            setName(user.displayName);
            setNationalId(user.nationalId);
            setPhoneNumber(user.phoneNumber);
            setAddress(user.address);
            setBirthDay(user.birthDay);
        }
    },[user])

    const handleUpdate = async () => {
        setIsLoading(true);
        const formData = {
            displayName: name,
            nationalId: nationalId,
            phoneNumber: phoneNumber,
            address: address,
            birthDay: birthDay
        };

        const data = await userService.updateUserInfo(user.id, formData);
        if(data != null){
            updateUserState(data);
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            // window.location.reload();
        }
        else{
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }
    return (
        <div className="container mt-5" style={{backgroundColor: "white", width: "80%", padding: "50px 0", borderRadius: "6px"}}>
            {isLoading && <Spinner/>}
            <div className={cx("avatar")}>
                <img alt="user_avatar" src="https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg" />
            </div>
            <div className={cx('add-form') + ' container-fluid w-75'}>
                <form>
                    <div className="row mt-5">
                        <div className='col-md-6'>
                            <label htmlFor="totalPage">Họ tên</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="text" id="totalPage" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="item1">Số điện thoại</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="text" id="item1" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className='col-md-6'>
                            <label htmlFor="nationalId">CCCD/CMT</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="text" id="nationalId" value={nationalId} onChange={(e) => setNationalId(e.target.value)} />
                        </div>
                        <div className='col-md-6'>
                            <label htmlFor="address">Địa chỉ</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className='col-md-6'>
                            <label htmlFor="birthday">Ngày sinh</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="date" id="birthday" value={birthDay ? new Date(birthDay).getFullYear() + "-" + String(new Date(birthDay).getMonth() + 1).padStart(2, "0") + "-" + String(new Date(birthDay).getDate()).padStart(2, "0") : ''} onChange={(e) => setBirthDay(e.target.value)} />
                        </div>
                    </div>


                    <p className={cx('add-new-btn') + ' mt-5 btn btn-primary'} onClick={handleUpdate}>Cập nhật</p>
                </form>
            </div>
        </div>
    );
}

export default PersonalInfo;