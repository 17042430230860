import classNames from "classnames/bind";

import styles from './Settings.module.scss';
import Layout from "../../components/Layout";
import Tab from "../../components/Tab";
import AccountInfo from "../../components/AccountInfo";
import PersonalInfo from "../../components/PersonalInfo";
import BankAccountInfo from "../../components/BankAccountInfo";
import PasswordInfo from "../../components/PasswordInfo";

const cx = classNames.bind(styles);

function Settings() {
    const activeKey = 1;
    const items = [
        {
            key: 1,
            label: "Thông tin cá nhân",
            children: <PersonalInfo/>
        },
        {
            key: 2,
            label: "Tài khoản",
            children: <AccountInfo/>
        },
        {
            key: 3,
            label: "Tài khoản ngân hàng",
            children: <BankAccountInfo/>
        },
        {
            key: 4,
            label: "Mật khẩu",
            children: <PasswordInfo/>
        },
    ]
    return (  
        <Layout>
            <div className={cx("wrapper")}>
            <p className="m-0 fw-bolder">Thiết lập tài khoản</p>
            <Tab defaultActiveKey={activeKey} items={items}/>
            </div>
        </Layout>
    );
}

export default Settings;