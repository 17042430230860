import axios from 'axios';

const libApiUrl = process.env.REACT_APP_LIBRARY_API_URL;

const getPublishers = async () => {
    try {
        const response = await axios.get(libApiUrl + '/Publishers');
        return response.data;
    } catch (error) {

        console.log(error);
        return null;
    }
};

const getAuthors = async () => {
    try {
        const response = await axios.get(libApiUrl + '/Authors');
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
};



export const externalService = {
    getAuthors, getPublishers
}