import classNames from "classnames/bind";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState, useEffect } from "react";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import { useParams } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";

import styles from './BookDetailItem.module.scss';
import Modal from "../Modal";
import StepComponent from "../StepComponent";
import RenderPDF from "../RenderPDF";
import Spinner from "../Spinner";
import { pageService, bookService, pdfService } from "../../services";
const cx = classNames.bind(styles);

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const pageSize = 12;

function BookDetailItem() {

    const user = useSelector(state => state.auth.login?.currentUser);
    const [modalOpen, setModalOpen] = useState(false);
    const [pageModalOpen, setPageModalOpen] = useState(false);
    const [newPageModal, setNewPageModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [selectedPage, setSelectedPage] = useState(0);
    const [pageList, setPageList] = useState([]);
    const [totalPaginationPage, setTotalPaginationPage] = useState(1);
    const [pdfList, setPdfList] = useState([]);
    const [currentPageIndex, setCurrentPageIndex] = useState(0);
    const [pageAddedNumber, setPageAddedNumber] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [selectedImageList, setSelectedImageList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { id, type } = useParams();

    useEffect(() => {
        const getBook = async () => {
            try {
                const data = await bookService.getBookById(id);
                if (data != null) {
                    // setBook(data.book);
                    setTotalPaginationPage(Math.ceil(data.book.totalPage / 12));
                    setTotalPage(data.book.totalPage);
                }
            } catch (error) {
                console.log(error);
            }
        }
        getBook();

    }, [id]);

    useEffect(() => {
        const fetchAPI = async () => {
            setIsLoading(true);
            const pdfs = [];
            const data = await pageService.getPages(id, pageNumber, pageSize);
            // console.log("pdfs dau: " + pdfs);

            for (let i = 0; i < data.length; i++) {
                const fileName = `Page_${data[i].pageIndex}`;
                const pdf_item = await pdfService.getPDF(id, fileName);
                // console.log("pdf_item: " + pdf_item);
                // console.log("pdfs dau: " + pdfs);

                if(pdf_item != null) pdfs.push(pdf_item);
                // console.log("pdfs cuoi: " + pdfs);

            }
            // console.log("pdfs cuoi: " + pdfs);

            // console.log("PDF ne: " + pdfs);
            const newPageList = [];
            for (let i = 12 * (pageNumber - 1); i < pageNumber * 12; i++) {
                if (totalPage && i >= totalPage) {
                    break;
                }
                const foundPage = data.find((page) => page.pageIndex == i + 1);
                if (!foundPage) {
                    const item = {
                        url1: null,
                        pageIndex: i + 1,
                        currentStatus: 1,
                        bookId: id
                    }
                    newPageList.push(item);
                }
                else newPageList.push(foundPage);
            }
            setPageList(newPageList);
            setPdfList(pdfs);
            setIsLoading(false);
        }

        if (totalPage > 0) fetchAPI();
    }, [pageNumber, totalPage, id]);

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected + 1);
    };

    const openPageModal = (page) => {
        setSelectedPage(page);
        setPageModalOpen(true);
        const crrIndex = pageSize - ((pageSize * pageNumber) - page.pageIndex) - 1;
        setCurrentPageIndex(crrIndex);
    }

    const closePageModal = () => {
        setPageModalOpen(false);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const closeNewPageModal = () => {
        setNewPageModal(false);
    }

    const onOK = async () => {
        if (selectedImageList.length == 0) { closeModal(); return; }
        setIsLoading(true);
        const formData = new FormData();
        let pageNums = [];
        for (const file of selectedImageList) {
            formData.append('files', file);
            const fileName = file.name;
            const num = fileName.split('_')[1].split('.')[0];
            if (fileName.split("_")[0] == 'Trang' && !isNaN(num)) {
                if (pageNums.includes(num)) {
                    toast.warning('Trang bị trùng tên', {
                        position: 'top-right',
                        autoClose: 3000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        draggable: true
                    });
                    setIsLoading(false);
                    return;
                }
                pageNums.push(num);
            }
            else {
                toast.warning('Vui lòng đặt tên theo đúng quy tắc', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                setIsLoading(false);
                return;
            }
        }
        try {
            const data = await pageService.addListPageImage(id, user.id, formData);
            if (data != null) {
                toast.success('Thực hiện thành công', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                window.location.reload();
            }
        } catch (error) {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }
    const fileInputRef = useRef(null);

    const handleAddPage = async () => {
        const data = await pageService.addNewPage(id, pageAddedNumber);
        if (data != null) {
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
        else {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
    }

    const handleFileUpload = async (event) => {
        const files = event.target.files;
        setSelectedImageList(files);
    };
    return (

        <div className={cx("wrapper")}>
            {modalOpen && (
                <Modal isOpen={true} onClose={closeModal} onOK={onOK}>
                    <div className={cx("modal-container")}>
                        <p className="text-center fw-bold">Tải lên bản scan</p>
                        {selectedImageList.length != 0 && (<span className="">Danh sách các tệp được chọn: </span>)}
                        {Array.from(selectedImageList).map((file, index) => (
                            <span key={index}> {index == 0 ? file.name : "," + file.name} </span>
                        ))}
                        <div className={cx("upload-space") + " d-flex justify-content-center"}>
                            <input
                                multiple="multiple"
                                type="file"
                                accept=".jpg, .jpeg, .png"
                                id="fileInput"
                                className={cx("file-input")}
                                ref={fileInputRef}
                                onChange={handleFileUpload}
                            />
                            <label htmlFor="fileInput" className={cx("file-label")}>
                                <FontAwesomeIcon icon={faUpload} />
                                <span className={cx("button-text") + " ms-1"}>Tải lên</span>
                            </label>
                        </div>
                    </div>
                </Modal>
            )}

            {pageModalOpen && (
                <Modal width="100%" isOpen={true} onClose={closePageModal} onOK={() => { }} OKButton={false} CancelButton={false}>
                    <StepComponent closeModal={closePageModal} type={type} page={selectedPage} pdfData={pdfList[currentPageIndex]} />
                </Modal>
            )}

            {newPageModal && (
                <Modal width="40%" isOpen={true} onClose={closeNewPageModal} onOK={() => { }} OKButton={false}>
                    <h5>Thêm mới trang</h5>
                    <hr />
                    <div className="container">
                        <div className="info">
                            <label className="mb-1" htmlFor="page-num" style={{ fontSize: "14px", fontWeight: "bold", margin: "0" }}>Số trang</label>
                            <br />
                            <input style={{ border: "none", borderRadius: "3px", backgroundColor: "#ECF3FA", height: "38px", width: "300px", padding: "0 10px" }} onChange={(e) => setPageAddedNumber(e.target.value)} type="number" name="" id="" />
                        </div>

                        <div className="text-start mt-3">
                            <button className={cx("button-6")} onClick={handleAddPage}>
                                Thêm
                            </button>
                        </div>

                    </div>
                </Modal>
            )}

            {isLoading && (<Spinner />)}
            <div className="container">
                <div className="d-flex flex-row-reverse">
                    <p className="btn btn-light mb-2" style={{ fontSize: "14px", padding: "10px 15px", fontWeight: "bold", fontFamily: "Inter-Light" }} onClick={() => setNewPageModal(true)}>Thêm trang</p>
                    {type == 1 && (<p className="btn btn-light mb-2 me-3" style={{ fontSize: "14px", padding: "10px 15px", fontWeight: "bold", fontFamily: "Inter-Light" }} onClick={() => setModalOpen(true)}>Tải lên trang</p>)}
                </div>
                <div className={cx("pages-container") + " pages row mt-3"}>

                    {pdfList.length > 0 && (
                        pageList.map((page, index) => (
                            page.currentStatus == type ? (
                                <div className={cx("page") + " col-md-2 text-center"} onClick={() => openPageModal(page)}>
                                    <div className={cx("page-img-container")}>
                                        <div className={cx("page-pdf")}>
                                            {page.style != 0 && (
                                                <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                    <span>Trang trống</span>
                                                </div>
                                            )}
                                            {page.style == 0 && page.url1 && page.url1.split(".").at(-1) != "pdf" && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                            {page.style == 0 && (!page.url1 || page.url1.split(".").at(-1) == "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />}
                                        </div>
                                        <div className={cx("overlay")}>
                                        </div>
                                    </div>
                                    <p className={cx("page-num")}>{"Trang " + page.pageIndex}</p>
                                    {page.currentStatus == 1 && <p className={cx("page-status")}>Chưa scan</p>}
                                    {page.currentStatus == 2 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    {page.currentStatus == 3 && <p className={cx("page-status")}>Thiết lập bản text</p>}
                                    {page.currentStatus == 4 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    {page.currentStatus == 5 && <p className={cx("page-status")}>Thiết lập audio</p>}
                                    {page.currentStatus == 6 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                </div>)
                                :
                                (
                                    <div className={cx("page") + " col-md-2 text-center"} style={{ pointerEvents: 'none' }} onClick={() => openPageModal(page)}>
                                        <div className={cx("page-img-container")}>

                                            <div className={cx("page-pdf")}>
                                                {page.style != 0 && (
                                                    <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                        <span>Trang trống</span>
                                                    </div>
                                                )}
                                                {page.style == 0 && page.url1 && page.url1.split(".").at(-1) != "pdf" && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                {page.style == 0 && (!page.url1 || page.url1.split(".").at(-1) == "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />}
                                            </div>
                                            <div className={cx("overlay")}>
                                            </div>
                                        </div>
                                        <p className={cx("page-num")} style={{ color: "#ccc" }} >Trang {page.pageIndex}</p>
                                        {page.currentStatus == 1 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chưa scan</p>}
                                        {page.currentStatus == 2 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        {page.currentStatus == 3 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập bản text</p>}
                                        {page.currentStatus == 4 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        {page.currentStatus == 5 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập audio</p>}
                                        {page.currentStatus == 6 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    </div>
                                )
                        ))
                    )}

                    {pdfList.length == 0 && (
                        pageList.map((page, index) => (
                            page.currentStatus == type ? (
                                <div className={cx("page") + " col-md-2 text-center"} onClick={() => openPageModal(page)}>
                                    <div className={cx("page-img-container")}>
                                        <div className={cx("page-pdf")}>
                                            {(page.style != 0 || (page.style == 0 && !page.url1)) && (
                                                <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                    <span>Trang trống</span>
                                                </div>
                                            )}
                                            {page.style == 0 && page.url1 && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                            {/* {page.style == 0 && !page.url1 && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />} */}
                                        </div>
                                        <div className={cx("overlay")}>
                                        </div>
                                    </div>
                                    <p className={cx("page-num")}>{"Trang " + page.pageIndex}</p>
                                    {page.currentStatus == 1 && <p className={cx("page-status")}>Chưa scan</p>}
                                    {page.currentStatus == 2 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    {page.currentStatus == 3 && <p className={cx("page-status")}>Thiết lập bản text</p>}
                                    {page.currentStatus == 4 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    {page.currentStatus == 5 && <p className={cx("page-status")}>Thiết lập audio</p>}
                                    {page.currentStatus == 6 && <p className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                </div>)
                                :
                                (
                                    <div className={cx("page") + " col-md-2 text-center"} style={{ pointerEvents: 'none' }} onClick={() => openPageModal(page)}>
                                        <div className={cx("page-img-container")}>

                                            <div className={cx("page-pdf")}>
                                                {(page.style != 0 || (page.style == 0 && !page.url1)) && (
                                                    <div className="container d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                                                        <span>Trang trống</span>
                                                    </div>
                                                )}
                                                {page.style == 0 && page.url1 && <img className={cx("image")} style={{ width: "100%" }} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />}
                                                {/* {page.style == 0 && !page.url1 && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfList[index]} />} */}
                                            </div>
                                            <div className={cx("overlay")}>
                                            </div>
                                        </div>
                                        <p className={cx("page-num")} style={{ color: "#ccc" }} >Trang {page.pageIndex}</p>
                                        {page.currentStatus == 1 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chưa scan</p>}
                                        {page.currentStatus == 2 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        {page.currentStatus == 3 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập bản text</p>}
                                        {page.currentStatus == 4 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                        {page.currentStatus == 5 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Thiết lập audio</p>}
                                        {page.currentStatus == 6 && <p style={{ color: "#ccc" }} className={cx("page-status")}>Chờ kiểm duyệt</p>}
                                    </div>
                                )
                        ))
                    )}

                </div>
                {pageList.length > 0 && (
                    <div className={cx("pagination-container") + " page mt-2"}>
                        <ReactPaginate
                            nextLabel="Tiếp >"
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={3}
                            marginPagesDisplayed={1}
                            pageCount={totalPaginationPage}
                            previousLabel="< Trước"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

export default BookDetailItem;