import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from './Spinner.module.scss';

const cx = classNames.bind(styles);

function Spinner() {
    return (
        <div className={cx("loadingCircle")}>
            <FontAwesomeIcon icon={faSpinner} className={cx("spinnerIcon")} />
        </div>
    );
}

export default Spinner;