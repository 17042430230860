import React, { useEffect, useRef } from "react";
import * as pdfjsLib from 'pdfjs-dist/webpack';
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";


pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const RenderPDF = ({ pageIndex, pdfData }) => {
  const canvasRef = useRef(null);

  useEffect(() => {

    const loadPdf = async () => {
      const pdfBlobUrl = URL.createObjectURL(pdfData);

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      const pdf = await pdfjsLib.getDocument(pdfBlobUrl).promise;
      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });

      canvas.width = viewport.width;
      canvas.height = viewport.height;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };
      await page.render(renderContext);
    };


    loadPdf();
  }, [pageIndex, pdfData])

  return (
    <canvas style={{width: "100%"}} ref={canvasRef} />
  );

};

export default RenderPDF;
