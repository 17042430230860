import classNames from "classnames/bind";
import { useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons";

import styles from './BookList.module.scss';
import Layout from "../../components/Layout";
import BookItem from "../../components/BookItem";
import { bookService } from "../../services";
import Spinner from "../../components/Spinner";

const cx = classNames.bind(styles);


function BookList() {
    const pageSize = 8;
    const user = useSelector(state => state.auth.login?.currentUser);

    const [bookList, setBookList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [crrType, setCrrType] = useState('');
    const [filterTitle, setFilterTitle] = useState('');

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const getJobCallback = (bookId, type) => {
        const updatedList = bookList.filter(data => data.book.id !== bookId || data.type !== type);
        setBookList(updatedList);
    }

    const removeTask = (id) => {
        const updatedList = bookList.filter(task => task.book.id !== id);
        setBookList(updatedList);
    }

    useEffect(() => {
        function checkValidTask(task) {
            return (
                (task.type === 1 && user.role.split(',').includes('Scan')) ||
                (task.type === 2 && user.role.includes('ApproveScan')) ||
                (task.type === 3 && user.role.includes('EditText')) ||
                (task.type === 4 && user.role.includes('ApproveText')) ||
                (task.type === 5 && user.role.includes('EditAudio')) ||
                (task.type === 6 && user.role.includes('ApproveAudio'))
            );
        }

        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await bookService.getAllTasks(currentPage, pageSize, user.role.split(', '));
                if (data !== null) {
                    setBookList(data.books);
                    setTotalPage(Math.ceil(data.total / pageSize));
                }
                else setBookList([]);

            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }

        const fetchFilterData = async () => {
            setIsLoading(true);
            try {
                const data = await bookService.searchTasks(crrType, filterTitle, currentPage, pageSize, user.role.split(', '));
                if (data !== null) {
                    setBookList(data.books.filter(checkValidTask));
                    setTotalPage(Math.ceil(data.total / pageSize));
                }
                else setBookList([]);

            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }

        if (crrType || filterTitle) fetchFilterData();
        else fetchData();
    }, [currentPage, crrType, filterTitle, user])

    const handleFilterChange = async (event) => {
        setCurrentPage(1);
        setCrrType(event.target.value);
    }

    return (
        <Layout>
            <div className={cx("wrapper")}>
                <div className={cx("title")}>
                    <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                        Danh sách công việc
                    </p>
                    <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faFilter} />
                            <select style={{ fontSize: "14px" }} onChange={handleFilterChange} class="form-select ms-3" aria-label="Default select example">
                                <option value="" selected>Tất cả</option>
                                <option value="1">Scan</option>
                                <option value="3">Text</option>
                                <option value="5">Audio</option>
                            </select>
                        </div>
                        <form class="d-flex">
                            <input class="form-control me-2" style={{ fontSize: "14px" }} type="search" placeholder="Tìm kiếm" aria-label="Search" />
                            <button class="btn btn-outline-success" onClick={() => setFilterTitle(document.querySelector('input[type="search"]').value)} type="button"><FontAwesomeIcon icon={faSearch} /></button>
                        </form>
                    </div>

                    <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                    <div className={cx("book-container") + " row mt-3 p-3"}>
                        {bookList.length === 0 && !isLoading && (
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <p style={{ color: "#989196" }}>Hiện chưa có công việc nào</p>
                            </div>
                        )}
                        {bookList && bookList.map((task, index) => (
                            <div key={index} className={cx("book-item") + " mb-3 d-flex justify-content-center"}>
                                <BookItem getJobCallBack={() => getJobCallback(task.book.id, task.type)} task={task} removeTask={() => removeTask(task.book.id)} />
                            </div>))}
                    </div>
                    {bookList.length > 0 && totalPage > 1 && (
                        <div className="page d-flex justify-content-end p-3">
                            <ReactPaginate
                                nextLabel="Tiếp >"
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={1}
                                pageCount={totalPage}
                                previousLabel="< Trước"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    )}
                </div>
                {isLoading && (<Spinner />)}
            </div>
        </Layout>
    );
}

export default BookList;