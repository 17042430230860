import request from "../api/config";
import { store } from "../redux/store";

const getUser = () => {
    const state = store.getState();
    const user = state.auth.login?.currentUser;
    return user;
};

const getPageById = async (pageId) => {
    try {
        const response = await request.get(`Page/get-by-id/${pageId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getPageByIndex = async (bookId, pageIndex) => {
    try {
        const response = await request.get(`Page/get-by-index/?bookId=${bookId}&pageIndex=${pageIndex}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getPageByIndexAndStatus = async (bookId, pageIndex, status) => {
    try {
        const response = await request.get(`Page/get-by-index-and-status/?bookId=${bookId}&pageIndex=${pageIndex}&status=${status}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getPages = async (bookId, pageNumber, pageSize) => {
    try {
        const response = await request.get(`Page/${bookId}?PageNumber=${pageNumber}&PageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateText = async (pageId, content) => {
    try {
        const response = await request.post(`Page/${pageId}/update-text?text=${content}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateCurrentStatus = async (pageId, status, userId) => {
    try {
        const response = await request.post(`Page/${pageId}/update-current-status?status=${status}&userId=${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const rejectPage = async (pageId, status, approverId) => {
    try {
        const response = await request.post(`Page/${pageId}/reject?status=${status}&approverId=${approverId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const addNewPage = async (bookId, pageNumber) => {
    try {
        const response = await request.post(`Page/add-empty-page/${bookId}?pageNumber=${pageNumber}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateUrl1 = async (pageId, pageIndex) => {
    try {
        const response = await request.post(`Page/update-url1/${pageId}?pageIndex=${pageIndex}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getPendingAmount = async (userId) => {
    try {
        const response = await request.get(`Page/get-pending/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getCompletedAmount = async (userId) => {
    try {
        const response = await request.get(`Page/get-completed/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalEarned = async (userId) => {
    try {
        const response = await request.get(`Page/get-total-earned/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalEarnedByWeek = async (userId) => {
    try {
        const response = await request.get(`Page/get-total-earned/by-week/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalEarnedByYear = async (userId) => {
    try {
        const response = await request.get(`Page/get-total-earned/by-year/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalSpendingByWeek = async () => {
    try {
        const response = await request.get(`Page/get-total-spending/by-week`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalSpendingByMonth = async () => {
    try {
        const response = await request.get(`Page/get-total-spending/by-month`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalSpending = async (isByWeek, isByMonth) => {
    try {
        const response = await request.get(`Page/get-total-spending`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const createPage = async (formdata) => {
    try {
        const response = await request.post(`Page/create`, formdata);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const addListPageImage = async (bookId, userId, files) => {
    const user = await getUser();
    const token = user?.accessToken;
    try {
        const response = await request.post(`Page/add-list-image/${bookId}?userId=${userId}`, files, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}



export const pageService = {
    getPageById, getPageByIndex, getPages, updateText, updateCurrentStatus,
    rejectPage, addNewPage, updateUrl1,
    getPendingAmount, getCompletedAmount,
    getTotalEarned, getTotalEarnedByWeek, getTotalEarnedByYear,
    getTotalSpendingByWeek, getTotalSpendingByMonth, getTotalSpending,
    createPage, addListPageImage, getPageByIndexAndStatus
}