import { useState, useEffect, useRef, Fragment } from "react";
import classNames from "classnames/bind";

import styles from './Modal.module.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function Modal({ isOpen, onClose, onOK, children, width = "auto", height = "auto", title, color = "black", OKButton = true, CancelButton = true, closeIcon = true }) {
  const [modalOpen, setModalOpen] = useState(isOpen);
  const cx = classNames.bind(styles);

  const modalOverlayRef = useRef(null);

  const modalCss = {
    width,
    height,
  }

  const closeModal = () => {
    setModalOpen(false);
    onClose();
  };

  useEffect(() => {
    const closeModal = () => {
      setModalOpen(false);
      onClose();
    };

    const handleOutsideClick = (event) => {
      if (event.target === modalOverlayRef.current) {
        closeModal();
      }
    };

    if (modalOpen) {
      document.addEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [modalOpen, onClose]);




  return (
    <>
      {modalOpen && (
        <div className={cx("modal-overlay")} ref={modalOverlayRef}>
          <div className={cx("modal")} style={modalCss} >
            {title && (
              <Fragment>
                <div className="d-flex pe-3 ps-3 justify-content-between align-items-center">
                  <div className="d-flex align-item-center">
                    <h6 className="p-0 m-0 fw-bolder" style={{ color: color }}>{title}</h6>
                  </div>
                  <a onClick={closeModal} className="fs-4 text-secondary" href="#"><FontAwesomeIcon icon={faClose} /></a>
                </div>
                <hr className="p-0" style={{ marginTop: "10px" }} />
              </Fragment>
            )}
            <div className={cx("modal-content")}>
              {children}
            </div>


            {CancelButton && (<button className={cx("modal-close") + " mb-3"} onClick={closeModal}>
              Đóng
            </button>)}

            {OKButton && (<button className={cx("modal-close") + ' btn btn-primary'} onClick={onOK}>
              OK
            </button>)}

          </div>
        </div>
      )}
    </>
  );
}

export default Modal;