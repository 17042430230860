import request from "../api/config";
import { toast } from "react-toastify";

const getAmount = async () => {
    try {
        const response = await request.get('book/amount');
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const addNewBook = async (user, formData) => {
    const token = user?.accessToken;
    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
    };
    try {
        const response = await request.post('Book', formData, { headers });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getAllTasks = async (pageNumber, pageSize, roles) => {
    try {
        const formData = {
            pageNumber,
            pageSize,
            roles
        };
        const response = await request.post('Book/tasks', formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getMyTasks = async (userId, pageNumber, pageSize) => {
    try {
        const response = await request.post(`Book/my-tasks?userId=${userId}&PageNumber=${pageNumber}&PageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getBookById = async (bookId) => {
    try {
        const response = await request.get(`book/${bookId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateTask = async (bookId, userId, taskType) => {
    try {
        const response = await request.post(`book/update-task?bookId=${bookId}&userId=${userId}&taskType=${taskType}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const completeTask = async (bookId, taskType) => {
    try {
        const response = await request.post(`book/complete-task?bookId=${bookId}&taskType=${taskType}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateRejectBookStep = async (bookId, status,) => {
    try {
        const response = await request.post(`Book/update-page-step/reject?bookId=${bookId}&currentStatus=${status}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}


const getAllBook = async (pageNumber, pageSize) => {
    try {
        const response = await request.post(`Book/get-all`, {
            "pageNumber": pageNumber,
            "pageSize": pageSize
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const searchBook = async (code, title, status) => {
    try {
        const response = await request.get(`Book/search?libCode=${code}&title=${title}&status=${status}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const addBookList = async (bookList) => {
    try {
        const response = await request.post(`Book/add-list`, bookList);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getInProgress = async () => {
    try {
        const response = await request.get(`Book/get-inprogress`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getCompletedBooks = async () => {
    try {
        const response = await request.get(`Book/get-completed`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalCompletedBooks = async () => {
    try {
        const response = await request.get(`Book/get-total-completed`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getRestOfBooks = async () => {
    try {
        const response = await request.get(`Book/get-rest`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateBook = async (bookId, formData) => {
    try {
        const response = await request.put(`Book/update/${bookId}`, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const createBook = async (formdata) => {
    try {
        const response = await request.post(`Book/create`, formdata);
        return response.data;
    } catch (error) {
        toast.error(`${error.response.data}`, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true
        });
        // alert(`Error: ${error.response.data}`);
        // console.log(error);
        return null;
    }
}

const deleteBook = async (bookId) => {
    try {
        const response = await request.delete(`Book/delete/${bookId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const publishBook = async (bookId, type) => {
    try {
        const response = await request.post(`Book/publish/${bookId}?type=${type}`);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response.data };
    }
}

const downloadBook = async (bookId, type) => {
    try {
        const response = await request.get(`Book/download/${bookId}?type=${type}`);
        return { success: true, data: response.data };
    } catch (error) {
        return { success: false, data: error.response.data };
    }
}

const searchTasks = async (type, title, pageNumber, pageSize, roles) => {
    try {
        const rolesQueryParam = roles.map(role => `roles=${role}`).join('&');
        const response = await request.get(`book/tasks/search?type=${type}&title=${title}&pageNumber=${pageNumber}&pageSize=${pageSize}&${rolesQueryParam}`);
        return response.data;
    } catch (error) {
        return null;
    }
}

const searchMyTasks = async (userId, type, title, pageNumber, pageSize) => {
    try {
        const response = await request.get(`book/my-tasks/search?userId=${userId}&type=${type}&title=${title}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        return null;
    }
}


export const bookService = {
    getAmount, addNewBook, getAllTasks,
    getMyTasks, getBookById, updateTask,
    completeTask, updateRejectBookStep,
    getAllBook, searchBook, addBookList,
    getInProgress, getCompletedBooks, getTotalCompletedBooks,
    getRestOfBooks, updateBook, createBook,
    deleteBook, publishBook, searchTasks, searchMyTasks,
    downloadBook
}


