import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import {AdminHome} from './pages/Admin/Home';
import Login from './pages/Login';
import './App.css';
import AddBook from './pages/AddBook';
import ProtectedRoute from './components/ProtectedRoute';
import UserList from './pages/UserList';
import BookList from './pages/BookList';
import AddUser from './pages/AddUser';
import Settings from './pages/Settings';
import ReceivedBook from './pages/ReceivedBook';
import BookDetail from './pages/BookDetail';
import AddBookWithPDF from './pages/AddBookWithPDF';
import Completed from './pages/Completed';
import CommonSettings from './pages/CommonSettings';
import AllBook from './pages/AllBook';
import EditUser from './pages/EditUser';
import EditBook from './pages/EditBook';
import PageDetail from './pages/PageDetail';

function App() {
  return (
    <Routes>
      <Route path='/' element = {<ProtectedRoute children={<Home/>}/>} />
      <Route path='/admin' element={<ProtectedRoute children={<AdminHome/>}/>}/>
      <Route path='/login' element = {<Login/>}/>
      <Route path='/add-book' element = {<ProtectedRoute children={<AddBook/>}/>}/>
      <Route path='/add-book-pdf' element = {<ProtectedRoute children={<AddBookWithPDF/>}/>}/>
      <Route path='/books' element = {<ProtectedRoute children={<BookList/>}/>}/>
      <Route path='/book/edit/:id' element = {<ProtectedRoute children={<EditBook/>}/>}/>
      <Route path='/collaborators' element = {<ProtectedRoute children={<UserList/>}/>}/>
      <Route path='/collaborator/edit/:id' element = {<ProtectedRoute children={<EditUser/>}/>}/>
      <Route path='/add-collaborators' element = {<ProtectedRoute children={<AddUser/>}/>}/>
      <Route path='/settings' element = {<ProtectedRoute children={<Settings/>}/>}/>
      <Route path='/received-book' element = {<ProtectedRoute children={<ReceivedBook/>}/>}/>
      <Route path='/book/detail/:id/:type' element = {<ProtectedRoute children={<BookDetail/>}/>}/>
      <Route path='/completed' element = {<ProtectedRoute children={<Completed/>}/>}/>
      <Route path='/common-settings' element = {<ProtectedRoute children={<CommonSettings/>}/>}/>
      <Route path='/all-book' element = {<ProtectedRoute children={<AllBook/>}/>}/>
      <Route path='/page-detail/:bookId/:pageIndex/:type' element = {<ProtectedRoute children={<PageDetail/>}/>}/>
    </Routes>
  );
}

export default App;
