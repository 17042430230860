import axios from "axios";
import jwtDecode from 'jwt-decode';
import { loginFail, loginStart, loginSuccess, logoutFail, logoutStart, logoutSuccess, updateUserRedux } from "../redux/authSlide";
import { store } from "../redux/store";
import { dispatch } from "../redux/store";
import { toast } from 'react-toastify';

const requestUrl = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

export const getUser = () => {
    const state = store.getState();
    const user = state.auth.login?.currentUser;
    return user;
};

let axiosJwt = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

axiosJwt.interceptors.request.use(
    async (config) => {
        const user = getUser();
        let date = new Date();
        const decodedToken = jwtDecode(user?.accessToken);
        if (decodedToken.exp < date.getTime() / 1000) {
            const data = await handleRefreshToken(user);
            if (data == null) {
                logoutUser(user, dispatch);
                return;
            }
            const refreshUser = {
                user: { ...user },
                accessToken: data.accessToken,
                refreshToken: data.refreshToken,
            };
            dispatch(loginSuccess(refreshUser));
            config.headers["Authorization"] = `Bearer ${data.accessToken}`;
        }
        else {
            config.headers["Authorization"] = `Bearer ${user.accessToken}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


const handleRefreshToken = async (user) => {
    try {
        const res = await requestUrl.post('Auth/refresh-token', { userId: user?.id }, { withCredentials: true });
        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const loginUser = async (user, dispatch, navigate) => {
    dispatch(loginStart());
    try {
        const res = await requestUrl.post("Auth/login", user, { withCredentials: true });
        dispatch(loginSuccess(res.data));
        if (res.data != null) return true;
        return false;
        // navigate("/");
    } catch (error) {
        dispatch(loginFail());
        return false;
    }
}

export const updateUserState = async (user) => {
    try {
        dispatch(updateUserRedux(user));
        // state.auth.login?.currentUser = user;
        // dispatch(loginSuccess(user));
        if (user != null) return true;
        return false;
    } catch (error) {
        dispatch(loginFail());
        return false;
    }
}

export const logoutUser = async (user, dispatch) => {
    const formData = {
        token: user.refreshToken,
        userId: user.id
    };
    dispatch(logoutStart());
    try {
        const res = await requestUrl.post('Auth/logout', formData);
        dispatch(logoutSuccess());
    } catch (error) {
        console.log(error);
        dispatch(logoutFail());
    }
}

// export const addNewBook = async (user, formData) => {
//     const token = user?.accessToken;
//     const headers = {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'multipart/form-data',
//     };
//     try {
//         const response = await axiosJwt.post('Book', formData, { headers });
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getAllTasks = async (pageNumber, pageSize, roles) => {
//     try {
//         const formData = {
//             pageNumber,
//             pageSize,
//             roles
//         };
//         const response = await axiosJwt.post('Book/tasks', formData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getMyTasks = async (userId, pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.post(`Book/my-tasks?userId=${userId}&PageNumber=${pageNumber}&PageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getBookById = async (bookId) => {
//     try {
//         const response = await axiosJwt.get(`Book/${bookId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateTask = async (bookId, userId, taskType) => {
//     try {
//         const response = await axiosJwt.post(`Book/update-task?bookId=${bookId}&userId=${userId}&taskType=${taskType}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const completeTask = async (bookId, taskType) => {
//     try {
//         const response = await axiosJwt.post(`Book/complete-task?bookId=${bookId}&taskType=${taskType}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getPages = async (bookId, pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.get(`Page/${bookId}?PageNumber=${pageNumber}&PageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateText = async (pageId, content) => {
//     try {
//         const response = await axiosJwt.post(`Page/${pageId}/update-text?text=${content}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getPDF = async (bookId, fileName) => {
//     try {
//         const response = await axiosJwt.get(`Pdf/page/${bookId}?fileName=${fileName}`, {
//             responseType: 'blob',
//         });
//         return response.data;

//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateCurrentStatus = async (pageId, status, userId) => {
//     try {
//         const response = await axiosJwt.post(`Page/${pageId}/update-current-status?status=${status}&userId=${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const rejectPage = async (pageId, status, approverId) => {
//     try {
//         const response = await axiosJwt.post(`Page/${pageId}/reject?status=${status}&approverId=${approverId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateRejectBookStep = async (bookId, status,) => {
//     try {
//         const response = await axiosJwt.post(`Book/update-page-step/reject?bookId=${bookId}&currentStatus=${status}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getHistory = async (workerId, pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.get(`PageHistory/get-all/${workerId}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const createUser = async (bodyData) => {
//     try {
//         const response = await axiosJwt.post(`User/register/`, bodyData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getAllUser = async (pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.post(`User/get-all?PageNumber=${pageNumber}&PageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalUser = async () => {
//     try {
//         const response = await axiosJwt.get(`User/get-total-user`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getAllBook = async (pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.post(`Book/get-all`, {
//             "pageNumber": pageNumber,
//             "pageSize": pageSize
//           });
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const searchUser = async (phoneNumber, displayName, role) => {
//     try {
//         const response = await axiosJwt.get(`User/search?PhoneNumber=${phoneNumber}&DisplayName=${displayName}&Role=${role}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getUserById = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`User/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const searchBook = async (code, title, status) => {
//     try {
//         const response = await axiosJwt.get(`Book/search?libCode=${code}&title=${title}&status=${status}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const addBookList = async (bookList) => {
//     try {
//         const response = await axiosJwt.post(`Book/add-list`, bookList);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const addNewPage = async (bookId, pageNumber) => {
//     try {
//         const response = await axiosJwt.post(`Page/add-empty-page/${bookId}?pageNumber=${pageNumber}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const uploadImage = async (formData) => {
//     try {
//         const response = await axiosJwt.post(`Image/upload`, formData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateUrl1 = async (pageId, pageIndex) => {
//     try {
//         const response = await axiosJwt.post(`Page/update-url1/${pageId}?pageIndex=${pageIndex}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateUserInfo = async (userId, formData) => {
//     try {
//         const response = await axiosJwt.put(`User/update/${userId}`, formData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const changePassword = async (formData) => {
//     try {
//         const response = await axiosJwt.post(`User/change-password`, formData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getPendingAmount = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-pending/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getCompletedAmount = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-completed/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalEarned = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-earned/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalEarnedByWeek = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-earned/by-week/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalEarnedByYear = async (userId) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-earned/by-year/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalSpendingByWeek = async () => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-spending/by-week`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalSpendingByMonth = async () => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-spending/by-month`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalSpending = async (isByWeek, isByMonth) => {
//     try {
//         const response = await axiosJwt.get(`Page/get-total-spending`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getInProgress = async () => {
//     try {
//         const response = await axiosJwt.get(`Book/get-inprogress`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getCompletedBooks = async () => {
//     try {
//         const response = await axiosJwt.get(`Book/get-completed`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getTotalCompletedBooks = async () => {
//     try {
//         const response = await axiosJwt.get(`Book/get-total-completed`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getRestOfBooks = async () => {
//     try {
//         const response = await axiosJwt.get(`Book/get-rest`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateBook = async (bookId, formData) => {
//     try {
//         const response = await axiosJwt.put(`Book/update/${bookId}`, formData);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const uploadBookCover = async (bookId, formData) => {
//     const user = await getUser();
//     const token = user?.accessToken;
//     try {
//         const response = await axiosJwt.post(`image/book-cover/upload?bookId=${bookId}`, formData, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'multipart/form-data'
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getJobByNumberCode = async (numberCode) => {
//     try {
//         const response = await axiosJwt.get(`Job/get-by-number-code/${numberCode}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const getAllJob = async () => {
//     try {
//         const response = await axiosJwt.get(`Job/get-all`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const updateJob = async (jobId, formdata) => {
//     try {
//         const response = await axiosJwt.put(`Job/update/${jobId}`, formdata);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const createBook = async (formdata) => {
//     try {
//         const response = await axiosJwt.post(`Book/create`, formdata);
//         return response.data;
//     } catch (error) {
//         toast.error(`${error.response.data}`, {
//             position: 'top-right',
//             autoClose: 3000,
//             hideProgressBar: true,
//             closeOnClick: true,
//             draggable: true
//           });
//         // alert(`Error: ${error.response.data}`);
//         // console.log(error);
//         return null;
//     }
// }

// export const deleteBook = async (bookId) => {
//     try {
//         const response = await axiosJwt.delete(`Book/delete/${bookId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const createPage = async (formdata) => {
//     try {
//         const response = await axiosJwt.post(`Page/create`, formdata);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const addListPageImage = async (bookId, userId, files) => {
//     const user = await getUser();
//     const token = user?.accessToken;
//     try {
//         const response = await axiosJwt.post(`Page/add-list-image/${bookId}?userId=${userId}`, files, {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//                 'Content-Type': 'multipart/form-data'
//             }
//         });
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const publishBook = async (bookId) => {
//     try {
//         const response = await axiosJwt.post(`Book/publish/${bookId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const deleteUser = async (userId) => {
//     try {
//         const response = await axiosJwt.delete(`User/delete/${userId}`);
//         return response.data;
//     } catch (error) {
//         console.log(error);
//         return null;
//     }
// }

// export const searchTasks = async (type, title, pageNumber, pageSize, roles) => {
//     try {
//         const rolesQueryParam = roles.map(role => `roles=${role}`).join('&');
//         const response = await axiosJwt.get(`book/tasks/search?type=${type}&title=${title}&pageNumber=${pageNumber}&pageSize=${pageSize}&${rolesQueryParam}`);
//         return response.data;
//     } catch (error) {
//         return null;
//     }
// }

// export const searchMyTasks = async (userId, type, title, pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.get(`book/my-tasks/search?userId=${userId}&type=${type}&title=${title}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         return null;
//     }
// }

// export const searchPageHistories = async (userId, status, pageNumber, pageSize) => {
//     try {
//         const response = await axiosJwt.get(`PageHistory/search/${userId}?status=${status}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
//         return response.data;
//     } catch (error) {
//         return null;
//     }
// }


