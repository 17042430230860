import request from "../api/config";

const getPDF = async (bookId, fileName) => {
    try {
        const response = await request.get(`Pdf/page/${bookId}?fileName=${fileName}`, {
            responseType: 'blob',
        });
        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}


export const pdfService = {
    getPDF
}