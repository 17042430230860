import classNames from "classnames/bind";
import { useState, useEffect, Fragment } from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from './StepItem2.module.scss';
import RenderPDF from "../RenderPDF";
import Spinner from "../Spinner";
import { pageService, bookService } from "../../services";
import Modal from "../Modal";
import { getUser } from "../../api/request";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
/* eslint-disable eqeqeq */
const cx = classNames.bind(styles);

function StepItem2({ page, pdfData, previousPageIndex, nextPageIndex }) {
    const [content, setContent] = useState(page.textContent == null ? "" : page.textContent.replace(/\\n/g, '\n'));
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [denyModal, setDenyModal] = useState(false);
    const [user, setUser] = useState();

    useEffect(() => {
        setUser(getUser());
    }, []);

    const handleSaveText = async () => {
        setIsLoading(true);
        const contentWithLineBreaks = content.replace(/\n/g, '\\n');
        await pageService.updateText(page.id, contentWithLineBreaks)
        page.textContent = content;
        setIsLoading(false);
        toast.success('Lưu thành công', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true
        });

    }

    const openApproveModal = () => {
        setApproveModal(true);
    }

    const closeApproveModal = () => {
        setApproveModal(false);
    }

    const handleSubmitText = async () => {
        setIsLoading(true);
        await pageService.updateCurrentStatus(page.id, page.currentStatus, user.id);
        setIsLoading(false);
        toast.success('Thực hiện thành công', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true
        });
        closeModal();
        if(nextPageIndex != 0) window.location.href =  `/page-detail/${page.bookId}/${nextPageIndex}/${page.currentStatus}`
        else window.location.href = `/book/detail/${page.bookId}/${page.currentStatus}`
        // window.location.reload();
        // setTimeout(() => {
        //     page.currentStatus = page.currentStatus + 1;
        // }, 2000);

    }

    const handleReject = async () => {
        setIsLoading(true);
        await pageService.rejectPage(page.id, page.currentStatus, user.id);
        await bookService.updateRejectBookStep(page.bookId, page.currentStatus);
        setIsLoading(false);
        toast.success('Thực hiện thành công', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true
        });
        closeModal();
        if(nextPageIndex != 0) window.location.href =  `/page-detail/${page.bookId}/${nextPageIndex}/${page.currentStatus}`
        else window.location.href = `/book/detail/${page.bookId}/${page.currentStatus}`
        // window.location.reload();
        // setTimeout(() => {
        //     page.currentStatus = page.currentStatus + 1;
        // }, 2000);
    }

    const handleApprove = async () => {
        const updateWorker = await pageService.updateCurrentStatus(page.id, page.currentStatus, user.id);
        const updateApprover = await pageService.updateCurrentStatus(page.id, -4, user.id);
        if (updateWorker && updateApprover) {
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            // window.location.reload();
            if(nextPageIndex != 0) window.location.href =  `/page-detail/${page.bookId}/${nextPageIndex}/${page.currentStatus}`
            else window.location.href = `/book/detail/${page.bookId}/${page.currentStatus}`
        }
        else {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
    }

    const openModal = () => {
        setConfirmModalOpen(true);
    }

    const closeModal = () => {
        setConfirmModalOpen(false);
    }

    const openDenyModal = () => {
        setDenyModal(true);
    }

    const closeDenyModal = () => {
        setDenyModal(false);
    }

    const handleTextOnChange = (e) => {
        setContent(e.target.value)
    }

    return (
        <div className={cx("wrapper") + " mb-3"}>
            {confirmModalOpen && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeModal} onOK={handleSubmitText}>
                    <div className="container" style={{ width: "350px" }}>
                        {page.currentStatus % 2 === 1 && (
                            <Fragment>
                                {/* <p className="mt-0 fw-bold">Xác nhận nộp</p> */}
                                <p className="mt-0">Bạn có xác nhận nộp không?</p>
                            </Fragment>
                        )}
                        {page.currentStatus % 2 === 0 && (
                            <Fragment>
                                {/* <p className="mt-0 fw-bold">Xác nhận phê duyệt</p> */}
                                <p className="mt-0">Bạn có xác nhận phê duyệt không?</p>
                            </Fragment>)}
                    </div>
                </Modal>
            )}

            {approveModal && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeApproveModal} onOK={handleApprove}>
                    <div className="container" style={{ width: "350px" }}>
                        {/* <p className="mt-0 fw-bold">Xác nhận phê duyệt</p> */}
                        <p className="mt-0">Bạn có xác nhận phê duyệt không?</p>
                    </div>
                </Modal>
            )}

            {denyModal && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeDenyModal} onOK={handleReject}>
                    <div className="container" style={{ width: "350px" }}>
                        {/* <p className="mt-0 fw-bold">Xác nhận từ chối</p> */}
                        <p className="mt-0">Bạn có xác nhận từ chối không?</p>
                    </div>
                </Modal>
            )}
            {isLoading && (<Spinner />)}

            <div className={cx("title-container") + " d-flex justify-content-between align-items-center"}>
                <div className="title">
                    {page.currentStatus == 3 && <span className="p-0">Thiết lập bản text</span>}
                    {page.currentStatus == 4 && <span className="p-0">Kiểm duyệt bản text</span>}
                </div>
                <div className="action d-flex align-items-center">
                    {previousPageIndex > 0 && <a href={`/page-detail/${page.bookId}/${previousPageIndex}/${page.currentStatus}`}><FontAwesomeIcon icon={faAngleLeft} /></a>}
                    {/* <div className="d-inline" style={{ backgroundColor: "#CAE8D8", padding: "8px 10px", margin: "0 15px", borderRadius: "100%" }}>
                        <span>{page.pageIndex}</span>
                    </div> */}
                    <div class={cx("rounded-circle-container")}>
                        {page.pageIndex}
                    </div>
                    {nextPageIndex != 0 && (<a href={`/page-detail/${page.bookId}/${nextPageIndex}/${page.currentStatus}`}><FontAwesomeIcon icon={faAngleRight} /></a>)}
                </div>
            </div>

            <div className={cx("main-container")}>
                <div className="row" style={{ minHeight: "650px" }}>
                    <div className={cx("page-img") + " col-4"}>
                        {page.url1 && page.url1.split(".").at(-1) !== "pdf" && (<img className={cx("image")} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />)}
                        {/* {pdfData && <RenderPDF pageIndex={page.pageIndex} pdfData />} */}
                        {(!page.url1 || page.url1.split(".").at(-1) === "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfData} />}
                    </div>
                    <div className={cx("content") + " col-8"}>
                        <textarea disabled={(page.currentStatus != 3 && page.currentStatus != 4)} style={{ width: "100%", height: "100%" }}
                            placeholder={content !== null ? "" : "Chưa có dữ liệu"}
                            value={content}
                            onChange={handleTextOnChange}
                        >
                        </textarea>
                    </div>
                </div>
                <div className="text-end mt-4 mb-5">
                    <button onClick={handleSaveText} className="btn btn-primary ms-3" style={{ height: "40px" }}>
                        <span style={{ fontSize: "14px", fontFamily: "Inter-Regular", width: "75px", display: "block" }}>Lưu</span>
                    </button>
                    {page.currentStatus == 3 && (
                        <button onClick={openModal} className="btn btn-success ms-3" style={{ height: "40px" }}>
                            <span style={{ fontSize: "14px", fontFamily: "Inter-Regular", width: "75px", display: "block" }}>Nộp</span>
                        </button>
                    )}
                    {page.currentStatus == 4 && (
                        <Fragment>
                            <button onClick={openApproveModal} className="btn btn-success ms-3" style={{ height: "40px" }}>
                                <span style={{ fontSize: "14px", fontFamily: "Inter-Regular", width: "75px", display: "block" }}>Phê duyệt</span>
                            </button>
                            <button onClick={openDenyModal} className="btn btn-danger ms-3" style={{ height: "40px" }}>
                                <span style={{ fontSize: "14px", fontFamily: "Inter-Regular", width: "75px", display: "block" }}>Từ chối</span>
                            </button>
                        </Fragment>
                    )}
                </div>
            </div>


            {/* {(pdfData || page.url1) && (
                <div className="container">
                    <div className="container text-end">
                        {(page.currentStatus == 3 || page.currentStatus == 4) && (<p className={cx("save-btn") + " btn"} style={{ color: "#0d6efd" }} onClick={handleSaveText}>Lưu</p>)}
                        {page.currentStatus == 3 && <p className={cx("save-btn") + " btn"} style={{ color: "#0d6efd" }} onClick={openModal}>Nộp</p>}
                        {page.currentStatus == 4 && <p className={cx("save-btn") + " btn"} style={{ color: "#198754" }} onClick={openApproveModal}>Phê duyệt</p>}
                        {page.currentStatus == 4 && <p className={cx("save-btn") + " btn"} style={{ color: "#dc3545" }} onClick={openDenyModal}>Từ chối</p>}
                    </div>
                    <div className="row">
                        <div className={cx("page-img") + " col-4"}>
                            {page.url1 && page.url1.split(".").at(-1) !== "pdf" && (<img className={cx("image")} src={process.env.REACT_APP_API_URL + `image/get-scanned/${page.url1}`} alt="" />)}
                            {(!page.url1 || page.url1.split(".").at(-1) === "pdf") && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfData} />}
                        </div>
                        <div className={cx("content") + " col-8"}>
                            <textarea disabled={(page.currentStatus != 3 && page.currentStatus != 4)} style={{ width: "100%", height: "100%" }}
                                placeholder={content !== null ? "" : "Chưa có dữ liệu"}
                                value={content}
                                onChange={handleTextOnChange}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
            )}

            {!pdfData && !page.url1 && <span className="text-center">Không có thông tin</span>} */}
        </div>
    );
}

export default StepItem2;