import classNames from "classnames/bind";
import { useSelector } from "react-redux";
import { faBell, faBox, faBoxArchive, faChartColumn, faMoneyBill, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import styles from './Home.module.scss';
import InfoBox from "../../components/InfoBox";
import LineChart from "../../components/LineChart";
import {bookService, pageHistoryService, pageService} from '../../services';
import Layout from "../../components/Layout";


const cx = classNames.bind(styles);

const getStatusColor = (status) => {
    if (status === 1) {
        return 'text-danger';
    } else if (status === 2) {
        return 'text-success';
    } else {
        return '';
    }
};

const getPageNum = (task) => {
    const bookItem = task.book;
    if (task.type === 1) {
        const step = bookItem.step1;
        return step.totalPage - step.pageDone - step.pagePending;
    }
    else if (task.type === 2) {
        const step = bookItem.step1;
        return step.pagePending;
    }
    else if (task.type === 3) {
        const step = bookItem.step3;
        return step.totalPage - step.pageDone - step.pagePending;
    }
    else if (task.type === 4) {
        const step = bookItem.step3;
        return step.pagePending;
    }
    else if (task.type === 5) {
        const step = bookItem.step4;
        return step.totalPage - step.pageDone - step.pagePending;
    }
    else if (task.type === 6) {
        const step = bookItem.step4;
        return step.pagePending;
    }
};

function Home() {
    const user = useSelector(state => state.auth.login.currentUser);

    if (user.role.includes('Admin')) {
        window.location.href = "/admin";
    }

    const [lineChartData, setLineChartData] = useState(null);
    const [pendingAmount, setPendingAmount] = useState(0);
    const [completedAmount, setCompletedAmount] = useState(0);
    const [totalEarned, setTotalEarned] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [jobList, setJobList] = useState([]);
    const [historyList, setHistoryList] = useState([]);




    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    useEffect(() => {
        const fetchPendingData = async () => {
            const data = await pageService.getPendingAmount(user.id);
            setPendingAmount(data.amount);
        }

        const fetchCompletedData = async () => {
            const data = await pageService.getCompletedAmount(user.id);
            setCompletedAmount(data.amount);
        }

        const fetchTotalEarnedData = async () => {
            const data = await pageService.getTotalEarned(user.id);
            setTotalEarned(data.total);
        }

        fetchPendingData();
        fetchCompletedData();
        fetchTotalEarnedData();
    }, [user]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (user.id) {
                    const data = await bookService.getMyTasks(user.id, 1, 5);
                    if (data != null) {
                        setJobList(data.books);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [user.id]);

    useEffect(() => {
        const fetchHistoryData = async () => {
            try {
                if (user.id) {
                    const data = await pageHistoryService.getHistory(user.id, 1, 10);
                    if (data != null && data.result.length > 0) {
                        setHistoryList(data.result);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchHistoryData();
    }, [jobList, user]);

    useEffect(() => {

        const getEarnedByWeek = async () => {
            const total = await pageService.getTotalEarnedByWeek(user.id);
            if (total != null) {
                const lineData = {
                    labels: ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"],
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "rgb(255, 99, 132)",
                            borderColor: "rgb(255, 99, 132)",
                            data: total.result,
                        },
                    ],
                };
                setLineChartData(lineData);
            }
            else {
                const labels = ["Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7", "Chủ nhật"];
                const lineData = {
                    labels: labels,
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "rgb(255, 99, 132)",
                            borderColor: "rgb(255, 99, 132)",
                            data: [],
                        },
                    ],
                };
                setLineChartData(lineData);
            }
        }

        const getEarnedByYear = async () => {
            const total = await pageService.getTotalEarnedByYear(user.id);
            if (total != null) {
                const lineData = {
                    labels: ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"],
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "rgb(255, 99, 132)",
                            borderColor: "rgb(255, 99, 132)",
                            data: total.result,
                        },
                    ],
                };
                setLineChartData(lineData);
            }
            else {
                const labels = ["T1", "T2", "T3", "T4", "T5", "T6", "T7", "T8", "T9", "T10", "T11", "T12"];
                const lineData = {
                    labels: labels,
                    datasets: [
                        {
                            label: "SỐ TIỀN",
                            backgroundColor: "rgb(255, 99, 132)",
                            borderColor: "rgb(255, 99, 132)",
                            data: [],
                        },
                    ],
                };
                setLineChartData(lineData);
            }
        }

        const initial = async () => {
            if (selectedOption === "month") {
                getEarnedByYear();
            }
            else {
                getEarnedByWeek();
            }
        };

        initial();
    }, [selectedOption, user]);



    return (
        <Layout>
            <div className={cx("wrapper")}>
                <p className="m-0" style={{ fontFamily: "Inter-SemiBold" }}>TRANG CHỦ</p>
                <Fragment>
                    <div className="d-flex justify-content-between mt-3">
                        <InfoBox label="Tổng tiền" number={totalEarned.toLocaleString('vn')} icon={faMoneyBill} color="var(--bold-green-color)" />
                        <InfoBox label="Hoàn tất" number={completedAmount} icon={faBox} color="var(--blue-color)" />
                        <InfoBox label="Chờ kiểm duyệt" number={pendingAmount} icon={faBox} color="var(--orange-color)" />
                        <InfoBox label="Đang thực hiện" number={jobList.length} icon={faSpinner} color='var(--green-color)' />
                    </div>

                    <div className={cx("statistic-wrapper")}>
                        <div className={cx("statistic-title")}>
                            <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "14px" }}>
                                <FontAwesomeIcon icon={faChartColumn} />&ensp;
                                Thống kê
                            </p>
                            <hr style={{ color: "#c1b8b8", margin: "0" }} />
                        </div>
                        <div className={cx("statistic-content")}>
                            <div className="select-box-container mb-3 text-end">
                                <label htmlFor="select-box" className="me-3" style={{ fontSize: "14px" }}>Thống kê theo</label>
                                <select id="select-box" className="select-box" style={{ padding: "5px 10px" }} onChange={handleSelectChange}>
                                    <option value="week">Tuần</option>
                                    <option value="month">Tháng</option>
                                </select>
                            </div>
                            <div className={cx("statistic") + " container"}>
                                <div className="chart m-auto" style={{ width: "70%" }}>
                                    {lineChartData && <LineChart data={lineChartData} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginBottom: "80px" }}>
                        <div className="col-6">
                            <div className={cx("task-list-container")}>
                                <div className={cx("task-list-title")}>
                                    <div className="d-flex justify-content-between">
                                        <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "14px" }}>
                                            <FontAwesomeIcon icon={faBoxArchive} />&ensp;
                                            Công việc hiện tại ({jobList.length})
                                        </p>
                                        <p className={cx("all-btn") + " m-0"} style={{ fontSize: "14px", fontFamily: 'Inter-Regular', padding: "15px" }}>
                                            <NavLink className="text-decoration-none" to={'/received-book'}>Tất cả</NavLink>
                                        </p>
                                    </div>
                                    <hr style={{ color: "#c1b8b8", marginTop: "0" }} />
                                </div>

                                <div className={cx("task-list")}>
                                    <div className={cx("task-item-header") + " row"}>
                                        <p className="col-5">Tên sách</p>
                                        <p className="col-2 text-center">Số trang</p>
                                        <p className="col-5 text-end">Loại công việc</p>
                                    </div>
                                    {jobList.length === 0 && (<p className="text-center mt-5" style={{ color: "#969ba0" }}>Hiện không có công việc nào được nhận</p>)}
                                    {jobList.length > 0 && (
                                        jobList.map((job, index) => (
                                            <Fragment>
                                                <hr />
                                                <div className={cx("task-item") + " row"}>
                                                    <p className="col-5">{job.book.title}</p>
                                                    <p className="col-2 text-center">{getPageNum(job)}</p>
                                                    <p className="col-5 text-end">{job.typeText}</p>
                                                </div>
                                            </Fragment>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className={cx("notification-container")}>
                                <div className="">
                                    <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "14px" }}>
                                        <FontAwesomeIcon icon={faBell} />&ensp;
                                        Thông báo
                                    </p>
                                    <hr style={{ color: "#c1b8b8", marginTop: "0" }} />
                                </div>
                                <div className={cx("notification-list")}>
                                    {historyList.length === 0 && <p className="text-center mt-5" style={{ color: "#969ba0" }}>Hiện không có thông báo</p>}
                                    {historyList.length > 0 && (
                                        historyList.map((item, index) => (
                                            <p className={cx("notification-item") + ` ${getStatusColor(item.status)}`}>Trang {item.pageIndex} - {item.bookTitle} - {item.message}</p>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            </div>
        </Layout>
    )
}

export default Home;