import classNames from 'classnames/bind';
import { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

import styles from "./EditBook.module.scss";
import Layout from '../../components/Layout';
import Spinner from '../../components/Spinner';
import { bookService, externalService } from '../../services';


const cx = classNames.bind(styles);

function EditBook() {

    const { id } = useParams();
    const [libCode, setLibCode] = useState('');
    const [title, setTitle] = useState('');
    const [totalPage, setTotalPage] = useState(0);
    const [subTitle, setSubTitle] = useState('');
    const [publishYear, setPublishYear] = useState(0);
    const [publisher, setPublisher] = useState('');
    const [reIssue, setReIssue] = useState('');
    const [volume, setVolume] = useState(null);
    const [genre, setGenre] = useState('');
    const [author, setAuthor] = useState('');
    const [translator, setTranslator] = useState('');
    const [bookCoverUrl, setBookCoverUrl] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [defaultPublisherOption, setDefaultPublisherOption] = useState(null);
    const [defaultAuthorOption, setDefaultAuthorOption] = useState([]);
    const [authorOptions, setAuthorOptions] = useState([]);
  const [publisherOptions, setPublisherOptions] = useState([]);

    const imgUrl = process.env.REACT_APP_API_URL + "image/";


    useEffect(() => {
        const fetchBook = async () => {
            const data = await bookService.getBookById(id);
            if (data.book != null) {
                const book = data.book;
                setLibCode(book.libCode);
                setTitle(book.title);
                setTotalPage(book.totalPage);
                setSubTitle(book.subTitle);
                setPublishYear(book.publishYear);
                setPublisher(book.publisherName);
                setReIssue(book.reIssue);
                setVolume(book.volume);
                setGenre(book.genre);
                setAuthor(book.author);
                setTranslator(book.translator);
                setBookCoverUrl(book.bookCoverUrl);
                setDefaultPublisherOption({ label: book.publisherName, value: book.publisherName });
                book.author.split(',').forEach(item => {
                    setDefaultAuthorOption(prevState => [...prevState, { label: item, value: item }]);
                    // console.log(authorOptions);
                });
                // setDefaultAuthorOption({ label: book.author, value: book.author });
            }
        }

        const getAuthors = async () => {
            const data = await externalService.getAuthors();
            if (data != null) {
              const options = data.map(item => ({
                label: item.name,
                value: item.name
              }));
              setAuthorOptions(options);
            }
          }
      
          const getPublishers = async () => {
            const data = await externalService.getPublishers();
            if (data != null) {
              const options = data.map(item => ({
                label: item.name,
                value: item.name
              }));
              setPublisherOptions(options);
            }
          }

        fetchBook();
        getAuthors();
        getPublishers();

    }, [id]);

    const imageFileInputRef = useRef(null);

    function getFormattedCurrentDateTime() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');

        return `${year}${month}${day}_${hours}${minutes}${seconds}`;
    }

    const handleImageFileUpload = (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();


        reader.onload = (e) => {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
            const formattedDateTime = getFormattedCurrentDateTime();
            const extension = file.name.split('.').pop();
            const result = formattedDateTime + '.' + extension;
            setBookCoverUrl(`${id}/${result}`);
        };

        reader.readAsArrayBuffer(file);
        imageFileInputRef.current.value = null;
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        if(title.trim() === '' || libCode.trim() === '' || publisher.trim() === '' || author.trim() === '' || publishYear <= 0 || reIssue <= 0 || genre.trim() === '' || totalPage <= 0){
            toast.warning('Vui lòng nhập đầy đủ thông tin', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            setIsLoading(false);
            return;
        }

        const formData = {
            title: title,
            subTitle: subTitle,
            libCode: libCode,
            totalPage: totalPage,
            publisherName: publisher,
            author: author,
            translator: translator,
            volume: volume,
            publishYear: publishYear,
            genre: genre,
            bookCoverUrl: bookCoverUrl == null ? null : bookCoverUrl,
            reIssue: reIssue,
        };
        try {
            const data = await bookService.updateBook(id, formData);
            if (data != null) {
                if (selectedFile != null) {
                    const imgFormData = {
                        file: selectedFile,
                        fileName: bookCoverUrl
                    };

                    const uploadData = await bookService.uploadBookCover(id, imgFormData);
                    if (uploadData != null) {

                    }
                    else {
                        toast.error('Cập nhật ảnh bìa thất bại', {
                            position: 'top-right',
                            autoClose: 3000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            draggable: true
                        });
                    }
                }
                toast.success('Câp nhật sách thành công', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });

            }
            else {
                toast.error('Cập nhật sách thất bại', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
            }

        } catch (error) {
            console.error('Error:', error);
        }
        setIsLoading(false);
    };

    return (
        <Layout>
            <div className={cx("wrapper")}>
                {isLoading && <Spinner />}
                <div className={cx('add-form') + ' container-fluid'}>
                    <form onSubmit={handleSubmit}>
                        <div class="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                            <div class="card-header p-4 border-bottom border-300 bg-soft">
                                <div class="row g-3 justify-content-between align-items-center">
                                    <div class="col-12 col-md">
                                        <h5 class="text-900 mb-0" style={{ color: "rgb(75 89 109)", fontFamily: 'Inter-SemiBold', }} data-anchor="data-anchor" id="readonly-plain-text">Cập nhật thông tin sách</h5>
                                    </div>
                                </div>
                            </div>
                            <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin chung</p>
                            <div class="card-body p-0">
                                <div class="p-4 code-to-copy">
                                    <div className="row">
                                        <div className={cx("upload-container") + ' col-2'}>
                                            <label htmlFor="imageFile">Ảnh bìa <span style={{ color: "red" }}>*</span></label>
                                            <br />
                                            <div className="img">
                                                {bookCoverUrl && !previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={imgUrl + bookCoverUrl} alt="Book Cover" />}
                                                {previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={previewUrl} alt="Book Cover" />}
                                                {!previewUrl && !bookCoverUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={imgUrl + "Shared/empty.png"} alt="Book Cover" />}
                                                <br />
                                            </div>
                                            <input className={cx("file-input")} ref={imageFileInputRef} type="file" id="imageFile" accept="image/*" onChange={handleImageFileUpload} />
                                            <label htmlFor="imageFile" className={cx("file-label1") + ' mt-3'}>
                                                <p className="btn btn-outline-primary text-center" type="button" style={{ backgroundColor: "white", color: "blue", border: "blue solid 1px", borderRadius: "18px", fontSize: "14px" }}>Tải lên</p>
                                            </label>
                                        </div>

                                        <div className="col-10">
                                            <div className="row">
                                                <div className='col-md-3'>
                                                    <label htmlFor="libCode">Mã sách <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="text" id="bookCode" value={libCode} onChange={(e) => setLibCode(e.target.value)} />
                                                </div>
                                                <div className='col-md-9'>
                                                    <label htmlFor="title">Tiêu đề <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className="col-6">
                                                    <label htmlFor="publisher">Nhà xuất bản <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    {defaultPublisherOption && (<CreatableSelect placeholder={"Nhà xuất bản..."} defaultValue={defaultPublisherOption}  onChange={(selectedOption) => {setPublisher(selectedOption?.value);}} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={publisherOptions} />)}
                                                    
                                                    {/* <input className={cx('form-input') + ' w-100'} type="text" id="publisher" value={publisher} onChange={(e) => setPublisher(e.target.value)} /> */}
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="author">Tác giả <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    {defaultAuthorOption.length > 0 && (<CreatableSelect isMulti defaultValue={defaultAuthorOption} placeholder={"Tác giả..."} onChange={(selectedOption) => { setAuthor(selectedOption.map(item => item.label).join(', ')); }} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={authorOptions} />)}
                                                    {/* <input className={cx('form-input') + ' w-100'} type="text" id="author" value={author} onChange={(e) => setAuthor(e.target.value)} /> */}
                                                </div>
                                            </div>

                                            <div className="row mt-5">
                                                <div className='col-3'>
                                                    <label htmlFor="publishYear">Năm xuất bản <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="number" id="publishYear" value={publishYear} onChange={(e) => setPublishYear(e.target.value)} />
                                                </div>
                                                <div className='col-3'>
                                                    <label htmlFor="reissue">Lần tái bản <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="number" id="reissue" value={reIssue} onChange={(e) => setReIssue(e.target.value)} />
                                                </div>
                                                <div className="col-6">
                                                    <label htmlFor="genre">Thể loại <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="text" id="genre" value={genre} onChange={(e) => setGenre(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className="row mt-5 mb-5">
                                                <div className='col-3'>
                                                    <label htmlFor="totalPage">Số trang <span style={{ color: "red" }}>*</span></label>
                                                    <br />
                                                    <input className={cx('form-input') + ' w-100'} type="number" id="totalPage" value={totalPage} onChange={(e) => setTotalPage(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin bổ sung</p>
                            <div class="card-body p-0">
                                <div class="p-4 code-to-copy">
                                    <div className="row">
                                        <div className="col-6">
                                            <label htmlFor="transalator">Dịch giả</label>
                                            <br />
                                            <input className={cx('form-input') + ' w-100'} type="text" id="transalator" value={translator} onChange={(e) => setTranslator(e.target.value)} />
                                        </div>
                                        <div className='col-md-5'>
                                            <label htmlFor="subTitle">Tiêu đề phụ</label>
                                            <br />
                                            <input className={cx('form-input') + ' w-100'} type="text" id="subTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mt-5 mb-5">
                                        <div className='col-6'>
                                            <label htmlFor="volume">Tập</label>
                                            <br />
                                            <input className={cx('form-input') + ' w-100'} type="number" id="volume" value={volume} onChange={(e) => setVolume(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-end'>
                            <button className={cx('add-new-btn') + ' mt-3 btn btn-primary'} type="submit">Cập nhật</button>
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default EditBook;