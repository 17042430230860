import Layout from "../../components/Layout";
import classNames from "classnames/bind";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import styles from './PageDetail.module.scss';
import StepItem1 from "../../components/StepItem1";
import StepItem2 from "../../components/StepItem2";
import StepItem3 from "../../components/StepItem3";
import { pageService, pdfService } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackward } from "@fortawesome/free-solid-svg-icons";

const cx = classNames.bind(styles);

function PageDetail() {
    const {bookId, pageIndex, type} = useParams();

    const [data, setData] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);

    // useEffect(() => {
    //     const getPage = async () => {
    //         const data = await pageService.getPage(pageId);
    //         setPage(data.page);
    //     }
    //     getPage();
    // },[pageId]);
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const pageData = await pageService.getPageByIndexAndStatus(bookId, pageIndex, type);
                setData(pageData.data);
    
                if (pageData.data.page.url1.split(".").at(-1) == "pdf") {
                    const res = await pdfService.getPDF(bookId, `Page_${pageIndex}`);
                    setPdfFile(res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
    
        fetchData();
    }, [pageIndex]);

    return <Layout>
        <div className={cx("wrapper")}>
            <div className="pt-3" style={{paddingLeft: "20px"}}>
                <a className="btn btn-light" href={`/book/detail/${bookId}/${type}`}>
                    <FontAwesomeIcon icon={faBackward}/>
                    &nbsp;
                    Trở về</a>
            </div>
            {(type == 1 || type == 2) && <StepItem1/>}
            {(type == 3 || type == 4) && data != null && (data.page.url1.split(".").at(-1) != "pdf" || pdfFile) && <StepItem2 page={data.page} previousPageIndex={data.previousPageIndex} nextPageIndex={data.nextPageIndex} pdfData={pdfFile}/>}
            {(type == 5 || type == 6) && <StepItem3/>}
        </div>
    </Layout>
}

export default PageDetail;