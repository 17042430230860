import classNames from "classnames/bind";

import styles from "./Layout.module.scss";
import Header from '../Header';
import NavBar from "../NavBar";

const cx = classNames.bind(styles);

function Layout({ children }) {
    return (
        <div className={cx("wrapper")}>
            {/* <Header /> */}
            <div className={cx("navbar")}>
                <NavBar />
            </div>
            <div className={cx("main") + " h-100"}>
                <div className={cx("header")}>
                    <Header />
                </div>
                <div className={cx("content")}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Layout;