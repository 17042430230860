import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";


import styles from "./Header.module.scss";
import { logoutUser } from "../../api/request";


const cx = classNames.bind(styles);

function Header() {
    const user = useSelector(state => state.auth.login?.currentUser);
    const dispatch = useDispatch();

    const handleLogout = (e) => {
        logoutUser(user, dispatch);
    }

    return (
        <header className={`${cx("wrapper")} d-flex ps-3 pe-3 justify-content-between align-items-center text-white`}>
            <div className={cx("bars-icon")}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className={`${cx("information")} d-flex align-items-center`}>
                <div className={cx("notification") + " me-3"}>
                    <FontAwesomeIcon style={{color: "#908a8a"}} icon={faBell} />
                </div>
                {user ?
                    (
                        <>
                            <p className={`${cx("user-name")} m-0 me-2`} style={{color: "rgb(87 84 84)"}}>Hi, {user.username}</p>
                        </>
                    )
                    : <a href="/login" className={`${cx("user-name")} m-0 me-2`}>Login</a>}

                <div className="dropdown">
                    <div className="" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className={cx("user-avatar")}>
                            <img alt="user_avatar" src="https://png.pngtree.com/png-vector/20190710/ourlarge/pngtree-user-vector-avatar-png-image_1541962.jpg" />
                        <FontAwesomeIcon className="ms-2" style={{fontSize: "12px", color: "black"}} icon={faChevronDown} />
                        </div>
                    </div>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <li><a className="dropdown-item" href="/settings">Thiết lập tài khoản</a></li>
                        <li><span onClick={handleLogout} className={cx("log-out-btn") + " dropdown-item"}>Đăng xuất</span></li>
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;