import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

import CoverImage from "../../assets/images/login_cover.png";
import Spinner from "../../components/Spinner";
import { loginUser } from "../../api/request";

function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.login?.currentUser);
    useEffect(() => {
        if (user != null) navigate('/');
    }, [navigate, user])

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        const userInfo = {
            username: username,
            password: password
        }
        const result = await loginUser(userInfo, dispatch, navigate);
        if (result) navigate("/");
        else {
            toast.error('Đăng nhập không thành công', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }

    return (
        <div className="w-100" style={{ backgroundColor: "#F4F0F0" }}>
            <section className="vh-100 d-flex align-items-center">
                <div className="container-fluid h-custom h-75 w-75" style={{ backgroundColor: "#FFFFFF" }}>
                    <div className="row h-100" style={{ border: "solid 1px #ccc", borderRadius: "3px" }}>
                        <div className="col-md-6 d-flex flex-column align-items-center justify-content-center" style={{ backgroundColor: "#3FA0FA" }}>
                            <img src={CoverImage}
                                className="img-fluid" alt="Sample" />
                            <h3 className="text-center text-white w-100 fw-bold mt-3">Hệ thống số hoá sách</h3>
                        </div>
                        <div className="col-md-6 p-5 mt-5">
                            {isLoading && <Spinner />}
                            <form onSubmit={handleLogin}>
                                <h2 className="text-center mb-5 fw-bolder">Đăng nhập</h2>

                                <div className="form-outline mb-4">
                                    <label className="form-label fw-bold" for="username">Tên đăng nhập</label>
                                    <input type="text" id="username" className="form-control form-control-lg fs-6"
                                        placeholder="Tên đăng nhập" onChange={e => setUsername(e.target.value)} />
                                </div>

                                <div className="form-outline mb-3">
                                    <label className="form-label fw-bold" for="password">Mật khẩu</label>
                                    <input type="password" id="password" className="form-control form-control-lg fs-6"
                                        placeholder="Mật khẩu" onChange={e => setPassword(e.target.value)} />
                                </div>

                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="form-check mb-0">
                                        <input className="form-check-input me-2" type="checkbox" value="" id="form2Example3" />
                                        <label className="form-check-label" for="form2Example3">
                                            Ghi nhớ
                                        </label>
                                    </div>
                                    <a href="#!" className="text-primary">Quên mật khẩu?</a>
                                </div>

                                <div className="text-center mt-4 pt-2">
                                    <button type="submit" className="btn btn-primary btn-lg"
                                        style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                                    >Đăng nhập</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Login;
