import React from "react";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';

const options = {
    scales: {
        y: {
            beginAtZero: true,
        },
    },
};

const LineChart = ({data}) => {
    return (
        <div>
            <div className="d-block" style={{width: "100%"}}>
                <Line className="itemm" data={data} options={options} />
            </div>
        </div>
    );
};

export default LineChart;