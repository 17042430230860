import classNames from "classnames/bind";
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";


import Layout from "../../components/Layout";
import style from './Completed.module.scss';
import { pageHistoryService } from "../../services";

const cx = classNames.bind(style);


function Completed() {

    const user = useSelector((state) => state.auth.login.currentUser);

    const [historyList, setHistoryList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItem, setTotalItem] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [crrStatus, setCrrStatus] = useState('');

    const pageSize = 10;

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const data = await pageHistoryService.getHistory(user.id, pageNumber, pageSize);
                if (data != null) {
                    var items = data.result.map((item, index) => ({
                        ...item,
                        order: ((pageNumber - 1) * pageSize) + index + 1,
                    }));
                    setHistoryList(items);
                    setTotalItem(data.total);
                }
            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        };

        const fetchFilterData = async () => {
            setIsLoading(true);
            try {
                const data = await pageHistoryService.searchPageHistories(user.id, crrStatus, pageNumber, pageSize);
                if (data != null) {
                    var items = data.result.map((item, index) => ({
                        ...item,
                        order: ((pageNumber - 1) * pageSize) + index + 1,
                    }));
                    setHistoryList(items);
                    setTotalItem(data.total);
                }

            } catch (error) {
                console.log(error);
            }
            setIsLoading(false);
        }

        if (crrStatus) fetchFilterData();
        else fetchData();
    }, [pageNumber, crrStatus, user])

    const getStatusColor = (status) => {
        if (status === 'Từ chối') {
            return 'text-danger';
        } else if (status === 'Chấp thuận' || status === 'Hoàn thành') {
            return 'text-success';
        } else if (status === 'Chờ kiểm duyệt')
            return 'text-warning'; 
        else {
            return 'text-secondary';
        }
    };

    const handlePageChange = async (page) => {
        setPageNumber(page + 1);
    }

    const columns = [
        {
            field: 'order', headerName: 'STT', width: 70, align: 'center', headerClassName: cx('custom-header-class'),
            cellClassName: cx('cell-text')
        },
        { field: 'bookTitle', headerName: 'Tên sách', width: 300, sortable: false, cellClassName: cx('cell-text') },
        {
            field: 'pageIndex', headerName: 'Trang', width: 150, sortable: false, align: 'center',
            headerClassName: cx('custom-header-class'), cellClassName: cx('cell-text')
        },
        {
            field: 'jobType', headerName: 'Công việc', width: 150, cellClassName: cx('cell-text'),
            sortable: false,
            valueGetter: (params) => getJobName(params.row.jobType),
        },
        {
            field: 'message', headerName: 'Trạng thái', width: 200, sortable: false,
            align: 'center', headerClassName: cx('custom-header-class'),
            renderCell: (params) => (
                <div className={getStatusColor(params.value)}>{params.value}</div>
            )
        },
        {
            field: 'updateDate', headerName: 'Ngày cập nhật', width: 250, sortable: false, cellClassName: cx('cell-text'),
            headerClassName: cx('custom-header-class'), align: 'center',
            valueGetter: (params) => formatDate(params.row.updateDate),
        },
    ];

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const timeOptions = {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        };

        const time = new Intl.DateTimeFormat('vi-VN', timeOptions).format(date);

        const dateOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        };

        const dateStr = new Intl.DateTimeFormat('vi-VN', dateOptions).format(date);

        return `${time} ${dateStr}`;
    };

    const getJobName = (jobType) => {
        if (jobType === 0) return '';
        else if (jobType === 1) return 'Scan';
        else if (jobType === 2) return 'Kiểm duyệt scan';
        else if (jobType === 3) return 'Thiết lập text';
        else if (jobType === 4) return 'Kiểm duyệt text';
        else if (jobType === 5) return 'Thiết lập audio';
        else if (jobType === 6) return 'Kiểm duyệt audio';
    }

    const handleFilterChange = async (event) => {
        setCrrStatus(event.target.value);
    }

    return (
        <Layout>
            <div className={cx("wrapper")}>
                <div className={cx("main")}>
                    <p className="fw-bold m-0" style={{ padding: "15px", fontFamily: "Inter-SemiBold", color: "rgb(103, 97, 118)", fontSize: "16px" }}>
                        Công việc đã hoàn thành
                    </p>
                    <hr className="m-0 p-0" style={{ color: "#c1b8b8" }} />
                    <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon={faFilter} />
                            <select style={{ fontSize: "14px" }} onChange={handleFilterChange} class="form-select ms-3" aria-label="Default select example">
                                <option value="" selected>Trạng thái</option>
                                {(user.role.split(', ').includes("ApproveText") || user.role.split(', ').includes("ApproveAudio") || user.role.split(', ').includes("ApproveScan")) && (<option value="2">Hoàn thành</option>)}
                                {(user.role.split(', ').includes("EditText") || user.role.split(', ').includes("EditAudio") || user.role.split(', ').includes("Scan")) && (<><option value="2">Chấp thuận</option> <option value="0">Chờ kiểm duyệt</option></>)}
                                <option value="1">Từ chối</option>
                            </select>
                        </div>

                    </div>

                    <div className="list-table">
                        <div style={{ width: '100%' }}>
                            <style>
                                {`
                              .custom-header .MuiDataGrid-colCellTitle,
                              .custom-cell .MuiDataGrid-cell {
                                text-align: center !important;
                              }
                            // .MuiDataGrid-columnHeaderTitle{
                            //     font-weight: 500;
                            //     text-align: center;
                            //     // color: grey
                            // }
                            // .custom-header-class > div > div > div{
                            //     // margin: auto;
                            // }
                            // .MuiTablePagination-displayedRows {
                            //     margin: 0;
                            // }
                            
                            `}
                            </style>

                            <DataGrid
                                paginationMode="server"
                                loading={isLoading}
                                pageSizeOptions={[pageSize]}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: pageSize },
                                    },
                                }}
                                page={pageNumber - 1}
                                rowCount={totalItem}
                                onPaginationModelChange={(params) => handlePageChange(params.page)}
                                rows={historyList}
                                columns={columns}
                                className="custom-header"
                                autoHeight
                                sx={{
                                    borderRadius: 0,
                                    borderColor: {
                                        borderRight: 'white',
                                        borderLeft: 'white',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}


export default Completed;