import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        logout: {
            isLoading: false,
            isError: false,
        },
        login: {
            currentUser: null,
            isProgressing: false,
            isError: false,
        },
    },
    reducers: {
        logoutStart: (state) => {
            state.logout.isLoading = true;
        },
        logoutSuccess: (state) => {
            state.logout.isLoading = false;
            state.logout.isError = false;
            state.login.currentUser = null;
        },
        logoutFail: (state) => {
            state.logout.isError = true;
            state.logout.isLoading = false;
        },
        loginStart: (state) => {
            state.login.isProgressing = true;
        },
        loginSuccess: (state, action) => {
            state.login.isProgressing = false;
            state.login.isError = false;
            state.login.currentUser = {...action.payload.user, 'accessToken': action.payload.accessToken, 'refreshToken': action.payload.refreshToken};
        },
        loginFail: (state) => {
            state.login.isError = true;
            state.login.isProgressing = false;
        },
        updateUserRedux: (state, action) => {
            state.login.currentUser = {...state.login.currentUser, ...action.payload};
        }
    }
});

export const { loginStart, loginFail, loginSuccess, logoutStart, logoutFail, logoutSuccess, updateUserRedux } = authSlice.actions;
export default authSlice.reducer;