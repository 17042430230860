import request from "../api/config";
import { store } from "../redux/store";

const getUser = () => {
    const state = store.getState();
    const user = state.auth.login?.currentUser;
    return user;
};

const uploadImage = async (formData) => {
    try {
        const response = await request.post(`Image/upload`, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const uploadBookCover = async (bookId, formData) => {
    const user = await getUser();
    const token = user?.accessToken;
    try {
        const response = await request.post(`image/book-cover/upload?bookId=${bookId}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}



export const imageService = {
    uploadImage, uploadBookCover
}