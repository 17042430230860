import request from "../api/config";

const createUser = async (bodyData) => {
    try {
        const response = await request.post(`User/register/`, bodyData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getAllUser = async (pageNumber, pageSize) => {
    try {
        const response = await request.post(`User/get-all?PageNumber=${pageNumber}&PageSize=${pageSize}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getTotalUser = async () => {
    try {
        const response = await request.get(`User/get-total-user`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const searchUser = async (phoneNumber, displayName, role) => {
    try {
        const response = await request.get(`User/search?PhoneNumber=${phoneNumber}&DisplayName=${displayName}&Role=${role}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const getUserById = async (userId) => {
    try {
        const response = await request.get(`User/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const updateUserInfo = async (userId, formData) => {
    try {
        const response = await request.put(`User/update/${userId}`, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const changePassword = async (formData) => {
    try {
        const response = await request.post(`User/change-password`, formData);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

const deleteUser = async (userId) => {
    try {
        const response = await request.delete(`User/delete/${userId}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}




export const userService = {
    createUser, getAllUser, getTotalUser,
    searchUser, getUserById, updateUserInfo,
    changePassword, deleteUser
}