import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';


import styles from './BookItem.module.scss';
import Spinner from "../Spinner";
import { bookService } from "../../services";

const cx = classNames.bind(styles);

function BookItem({ task, received, removeTask }) {

    const [isLoading, setIsLoading] = useState(false);
    const [imgUrl, setImgUrl] = useState("");
    const [pageNum, setPageNum] = useState(0);

    const user = useSelector(state => state.auth.login?.currentUser);

    useEffect(() => {
        setImgUrl(process.env.REACT_APP_API_URL + "image/");
    }, []);


    useEffect(() => {
        const bookItem = task.book;
        if (task.type == 1) {
            const step = bookItem.step1;
            setPageNum(step.totalPage - step.pageDone - step.pagePending);
        }
        else if (task.type == 2) {
            const step = bookItem.step1;
            setPageNum(step.pagePending);
        }
        else if (task.type == 3) {
            const step = bookItem.step3;
            setPageNum(step.totalPage - step.pageDone - step.pagePending);
        }
        else if (task.type == 4) {
            const step = bookItem.step3;
            setPageNum(step.pagePending);
        }
        else if (task.type == 5) {
            const step = bookItem.step4;
            setPageNum(step.totalPage - step.pageDone - step.pagePending);
        }
        else if (task.type == 6) {
            const step = bookItem.step4;
            setPageNum(step.pagePending);
        }

    }, [task]);


    const handleGetJob = async () => {
        try {
            setIsLoading(true);
            const data = await bookService.updateTask(task.book.id, user.id, task.type)
            setIsLoading(false);
            if (data != null) {
                toast.success('Thành công', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                removeTask();

            }
            else {
                toast.error('Thất bại', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleComplete = async () => {
        try {
            setIsLoading(true);
            const data = await bookService.completeTask(task.book.id, task.type);
            setIsLoading(false);
            if (data != null) {
                toast.success('Thành công', {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                removeTask();

            }
            else {
                toast.error('Thất bại', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div className={cx("wrapper") + " d-flex justify-content-center"}>
            {isLoading && (<Spinner />)}
            <div className="d-flex flex-column w-100">
                <div className="row">
                    <div className="col-3">
                        <div className={cx("image")}>
                            {task.book.bookCoverUrl && <img src={imgUrl + task.book.bookCoverUrl} alt="" />}
                            {!task.book.bookCoverUrl && <img src={imgUrl + "Shared/empty.png"} alt="" />}
                        </div>
                    </div>

                    <div className="col-9">
                        <p className="m-0" style={{ fontSize: "13px" }}>{task.book.libCode}</p>
                        <p className={cx("title") + " m-0 fw-bold"} style={{ fontSize: "14px" }}>{task.book.title}</p>
                        <p className="mt-1 mb-1" style={{ fontSize: "13px" }}>Số trang: {pageNum}</p>
                        <p className="mt-0 mb-1" style={{ fontSize: "13px" }}>Giá: {task.price.toLocaleString("vn")}</p>
                        <p style={{ fontSize: "13px" }}>Công việc: {task.name}</p>
                    </div>
                </div>
                <div className="action-btn d-flex justify-content-center">
                    {!received && (
                        <button className={cx("receive-btn") + " btn btn-primary mt-3"} onClick={handleGetJob}>Nhận</button>
                    )}

                    {received && (
                        <div className="d-flex w-100">
                            <a href={`/book/detail/${task.book.id}/${task.type}`} className={cx("receive-btn") + " btn btn-primary mt-3 me-2"}>Thực hiện</a>
                            <p className={cx("receive-btn") + " btn btn-success mt-3"} onClick={handleComplete}>Hoàn thành</p>
                        </div>
                    )}
                </div>
            </div>
        </div >
    );
}

export default BookItem;