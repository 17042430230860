import classNames from "classnames/bind";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";

import styles from './NavBar.module.scss';
import { faAdd, faBook, faBookOpen, faGear, faHistory, faHome, faUserEdit, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import { Fragment, useEffect, useState } from "react";

const cx = classNames.bind(styles);

function NavBar() {
    const user = useSelector(state => state.auth.login?.currentUser);
    const [role, setRole] = useState('');

    useEffect(() => {
        setRole(user.role);
    }, [user]);


    return (
        <div className={cx("wrapper")}>
            <div className={cx("title") + ' text-center pt-3 mb-5'}>
                <a href="/" className="text-white text-decoration-none" style={{ fontFamily: "Inter-Bold" }}>SỐ HOÁ SÁCH</a>
            </div>
            <div className={cx("vertical-navbar")}>
                {role.includes('Admin') && (
                    <NavLink exact to="/admin" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faHome} className={cx("nav-icon")} />
                        Trang chủ
                    </NavLink>
                )}
                {!role.includes('Admin') && (
                    <NavLink exact to="/" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faHome} className={cx("nav-icon")} />
                        Trang chủ
                    </NavLink>
                )}

                {/(Scan|EditText|EditAudio|ApproveScan|ApproveText|ApproveAudio)/.test(role) && <Fragment>
                    <NavLink to="/books" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faBook} className={cx("nav-icon")} />
                        Công việc
                        {/* {/(Scan|EditText|EditAudio|ApproveScan|ApproveText|ApproveAudio)/.test(role) && "Công việc"} */}
                    </NavLink>
                    <NavLink to="/received-book" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faBookOpen} className={cx("nav-icon")} />
                        Công việc đã nhận
                        {/* {/(Scan|EditText|EditAudio|ApproveScan|ApproveText|ApproveAudio)/.test(role) && "Công việc đã nhận"} */}
                    </NavLink>
                    <NavLink to="/completed" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faHistory} className={cx("nav-icon")} />
                        Lịch sử
                    </NavLink>
                    <NavLink to="/settings" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                        <FontAwesomeIcon icon={faGear} className={cx("nav-icon")} />
                        Thiết lập tài khoản
                    </NavLink>
                </Fragment>}
                {(role.includes('Admin')) && (
                    <Fragment>
                        <NavLink to="/all-book" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                            <FontAwesomeIcon icon={faUserGroup} className={cx("nav-icon")} />
                            Danh sách sách
                        </NavLink>
                        <NavLink to="/collaborators" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                            <FontAwesomeIcon icon={faUserGroup} className={cx("nav-icon")} />
                            Cộng tác viên
                        </NavLink>
                        <NavLink to="/add-collaborators" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                            <FontAwesomeIcon icon={faUserEdit} className={cx("nav-icon")} />
                            Thêm mới cộng tác viên
                        </NavLink>
                        <NavLink to="/add-book" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                            <FontAwesomeIcon icon={faAdd} className={cx("nav-icon")} />
                            Thêm mới sách
                        </NavLink>
                        {/* <NavLink to="/common-settings" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                            <FontAwesomeIcon icon={faGear} className={cx("nav-icon")} />
                            Thiết lập chung
                        </NavLink> */}
                    </Fragment>
                )}


                <NavLink to="/add-book-pdf" className={(nav) => cx("nav-link", { active: nav.isActive })}>
                    <FontAwesomeIcon icon={faAdd} className={cx("nav-icon")} />
                    Thêm mới sách đã có PDF
                </NavLink>

            </div>
        </div >
    );
}

export default NavBar;