import classNames from "classnames/bind";
import { useState, useRef, useEffect } from "react";
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from 'react-select/creatable';



import styles from './AbbBookWithPDF.module.scss';
import Layout from "../../components/Layout";
import Spinner from '../../components/Spinner';
import { bookService, externalService } from "../../services";

const cx = classNames.bind(styles);

function AddBookWithPDF() {

  const [libCode, setLibCode] = useState('');
  const [title, setTitle] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [subTitle, setSubTitle] = useState('');
  const [publishYear, setPublishYear] = useState(0);
  const [publisher, setPublisher] = useState('');
  const [reIssue, setReIssue] = useState(0);
  const [volume, setVolume] = useState(null);
  const [genre, setGenre] = useState('');
  const [author, setAuthor] = useState('');
  const [translator, setTranslator] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [bookCover, setBookCover] = useState(null);
  const [pdfFileName, setPdfFilename] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [missingPages, setMissingPages] = useState('');
  const [pdfPageAmount, setPdfPageAmount] = useState(0);
  const [previewUrl, setPreviewUrl] = useState('');
  const [authorOptions, setAuthorOptions] = useState([]);
  const [publisherOptions, setPublisherOptions] = useState([]);

  const imgUrl = process.env.REACT_APP_API_URL + "image/";

  const pdfFileInputRef = useRef(null);
  const imageFileInputRef = useRef(null);
  const user = useSelector(state => state.auth.login?.currentUser);

  useEffect(() => {
    const getAuthors = async () => {
      const data = await externalService.getAuthors();
      if (data != null) {
        const options = data.map(item => ({
          label: item.name,
          value: item.name
        }));
        setAuthorOptions(options);
      }
    }

    const getPublishers = async () => {
      const data = await externalService.getPublishers();
      if (data != null) {
        const options = data.map(item => ({
          label: item.name,
          value: item.name
        }));
        setPublisherOptions(options);
      }
    }

    getAuthors();
    getPublishers();
  }, []);

  const handlePdfFileUpload = (event) => {

    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = async (e) => {
      setPdfFilename(file.name);
      setSelectedFile(file);
      const count = reader.result.match(/\/Type[\s]*\/Page[^s]/g).length;
      setPdfPageAmount(count);
    };
    pdfFileInputRef.current.value = null;
  };

  const handleImageFileUpload = (event) => {

    const file = event.target.files[0];
    // console.log(file);
    const reader = new FileReader();

    reader.onload = (e) => {
      setBookCover(file);
      setPreviewUrl(URL.createObjectURL(file));
    };

    reader.readAsArrayBuffer(file);
    pdfFileInputRef.current.value = null;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (libCode.trim() === "" || title.trim() === "" || publisher.trim() === "" || author.trim() === ""
      || genre.trim() === "" || selectedFile === null || bookCover == null) {
      toast.warning('Vui lòng nhập đầy đủ thông tin', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }

    if (totalPage <= 0) {
      toast.warning('Số trang không hợp lệ', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }
    // if (pdfPageAmount != totalPage) {
    //   toast.warning('Số trang không khớp với tệp PDF', {
    //     position: 'top-right',
    //     autoClose: 3000,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     draggable: true
    //   });
    //   return;
    // }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('Title', title);
    formData.append('bookCover', bookCover);
    formData.append('pdfFile', selectedFile);
    formData.append('SubTitle', subTitle);
    formData.append('LibCode', libCode);
    formData.append('TotalPage', totalPage);
    formData.append('PublisherName', publisher);
    formData.append('Author', author);
    formData.append('Translator', translator);
    formData.append('Volume', volume);
    formData.append('PublishYear', publishYear);
    formData.append('Genre', genre);
    formData.append('ReIssue', reIssue);
    formData.append('MissingPages', missingPages);
    try {
      const response = await bookService.addNewBook(user, formData);
      if (response != null) {
        toast.success('Thêm mới thành công', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });

        setLibCode('');
        setTitle('');
        setSubTitle('');
        setPublisher('');
        setAuthor('');
        setTranslator('');
        setGenre('');
        setVolume(null);
        setPublishYear(0);
        setTotalPage(0);
        setReIssue(0);
        setBookCover(null);
        setSelectedFile(null);
        setPdfFilename("");
        setPreviewUrl('');
      }
      else {
        toast.error('Thêm mới thất bại', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
      }

    } catch (error) {
      toast.error('Thêm mới thất bại', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
    }
    setIsLoading(false);
  };
  return (
    <Layout>
      <div className={cx("wrapper")}>
        {isLoading && <Spinner />}
        <div className={cx('add-form') + ' container-fluid'}>
          <form onSubmit={handleSubmit}>
            <div class="card shadow-none border border-300 my-4" data-component-card="data-component-card">
              <div class="card-header p-4 border-bottom border-300 bg-soft">
                <div class="row g-3 justify-content-between align-items-center">
                  <div class="col-12 col-md">
                    <h5 class="text-900 mb-0" style={{ color: "rgb(75 89 109)", fontFamily: 'Inter-SemiBold', }} data-anchor="data-anchor" id="readonly-plain-text">Thêm mới sách đã có PDF</h5>
                  </div>
                </div>
              </div>
              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin chung</p>
              <div class="card-body p-0">
                <div class="p-4 code-to-copy">
                  <div className="row">
                    <div className="col-2">
                      <label htmlFor="imageFile">Ảnh bìa <span style={{ color: "red" }}>*</span></label>
                      <br />
                      <div className="img">
                        {previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={previewUrl} alt="Book Cover" />}
                        {!previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={imgUrl + "Shared/empty.png"} alt="Book Cover" />}
                        <br />
                      </div>
                      <input className={cx("file-input")} ref={imageFileInputRef} type="file" id="imageFile" accept="image/*" onChange={handleImageFileUpload} />
                      <label htmlFor="imageFile" className={cx("file-label1") + ' mt-3'}>
                        <p className="btn btn-outline-primary text-center" type="button" style={{ backgroundColor: "white", color: "blue", border: "blue solid 1px", borderRadius: "18px", fontSize: "14px" }}>Tải lên</p>
                      </label>
                    </div>
                    <div className="col-10">
                      <div className="row">
                        <div className='col-md-3'>
                          <label htmlFor="libCode">Mã sách <span style={{ color: "red" }}>*</span> </label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="bookCode" value={libCode} onChange={(e) => setLibCode(e.target.value)} />
                        </div>
                        <div className='col-md-9'>
                          <label htmlFor="title">Tiêu đề <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-6">
                          <label htmlFor="author">Tác giả <span style={{ color: "red" }}>*</span>
                            <em style={{ fontWeight: "normal" }}> (Ngăn cách bởi dấu phảy)</em>
                          </label>
                          <br />
                          <CreatableSelect isMulti placeholder={"Tác giả..."} onChange={(selectedOption) => { setAuthor(selectedOption.map(item => item.label).join(', ')); }} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={authorOptions} />
                          {/* <input className={cx('form-input') + ' w-100'} type="text" id="author" value={author} onChange={(e) => setAuthor(e.target.value)} /> */}
                        </div>
                        <div className="col-6">
                          <label htmlFor="publisher">Nhà xuất bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <CreatableSelect placeholder={"Nhà xuất bản..."} onChange={(selectedOption) => { setPublisher(selectedOption?.value); }} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={publisherOptions} />
                          {/* <input className={cx('form-input') + ' w-100'} type="text" id="publisher" value={publisher} onChange={(e) => setPublisher(e.target.value)} /> */}
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-6">
                          <label htmlFor="genre">
                            Thể loại <span style={{ color: "red" }}>*</span>
                            <em style={{ fontWeight: "normal" }}> (Ngăn cách bởi dấu phảy)</em>
                          </label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="genre" value={genre} onChange={(e) => setGenre(e.target.value)} />
                        </div>
                        <div className='col-3'>
                          <label htmlFor="reissue">Lần tái bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="reissue" value={reIssue} onChange={(e) => setReIssue(e.target.value)} />
                        </div>
                        <div className='col-3'>
                          <label htmlFor="publishYear">Năm xuất bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="publishYear" value={publishYear} onChange={(e) => setPublishYear(e.target.value)} />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className='col-3'>
                          <label htmlFor="totalPage">Số trang <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="totalPage" value={totalPage} onChange={(e) => setTotalPage(e.target.value)} />
                        </div>
                        <div className={cx("upload-container") + ' col-3 mb-5'}>
                          <label htmlFor="pdfFile">Tệp PDF <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx("file-input")} ref={pdfFileInputRef} type="file" id="pdfFile" accept=".pdf" onChange={handlePdfFileUpload} />
                          <label htmlFor="pdfFile" className={cx("file-label")}>
                            <FontAwesomeIcon icon={faUpload} />
                            <span className={cx("button-text") + " ms-1"}>Tải lên</span>
                          </label>
                          {pdfFileName && <p className="mt-2">{pdfFileName}</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin bổ sung</p>
              <div class="card-body p-0">
                <div class="p-4 code-to-copy">
                  <div className="row">
                    <div className='col-md-6'>
                      <label htmlFor="subTitle">Tiêu đề phụ</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="subTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="transalator">Dịch giả
                        <em style={{ fontWeight: "normal" }}> (Ngăn cách bởi dấu phảy)</em></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="transalator" value={translator} onChange={(e) => setTranslator(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mt-5 mb-5">
                    <div className='col-3'>
                      <label htmlFor="missingPages">Trang thiếu <em style={{ fontWeight: "normal" }}> (Ngăn cách bởi dấu phảy)</em></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="missingPages" value={missingPages} onChange={(e) => setMissingPages(e.target.value)} />
                    </div>
                    <div className='col-3'>
                      <label htmlFor="volume">Tập</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="number" id="volume" value={volume} onChange={(e) => setVolume(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-end mb-5">
                <button className={cx('add-new-btn') + ' btn btn-primary'} type="submit">Thêm mới</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
}

export default AddBookWithPDF;