import classNames from 'classnames/bind';
import { useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import styles from "./AddUser.module.scss";
import Layout from '../../components/Layout';
import { userService } from '../../services';
import Spinner from '../../components/Spinner';

const cx = classNames.bind(styles);

function AddUser() {
  const [fullName, setFullName] = useState('');
  const [birthDay, setBirthDay] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [nationalId, setNationalId] = useState(null);
  const [address, setAddress] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [taxCode, setTaxCode] = useState(null);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [confirmPassword, setConfirmPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const animatedComponents = makeAnimated();

  const roles = [
    { name: 'Admin', value: 'Admin' },
    { name: 'Scan', value: 'Scan' },
    { name: 'Thiết lập text', value: 'EditText' },
    { name: 'Thiết lập audio', value: 'EditAudio' },
    { name: 'Kiểm duyệt scan', value: 'ApproveScan' },
    { name: 'Kiểm duyệt text', value: 'ApproveText' },
    { name: 'Kiểm duyệt audio', value: 'ApproveAudio' }
  ];

  const handleRoleSelection = (selectedOptions) => {
    const selectedRoles = selectedOptions.map((option) => option.value);
    setSelectedRoles(selectedRoles);
    console.log(selectedRoles);
  };

  const roleOptions = roles.map((item) => ({
    value: item.value,
    label: item.name
  }));

  const handleCreateUser = async (e) => {
    e.preventDefault();
    if (fullName.trim() === "" || phoneNumber.trim() === "" || email.trim() === "" || username.trim() === ""
      || password.trim() === "" || confirmPassword.trim() === "" || selectedRoles.length === 0) {
      toast.warning('Vui lòng nhập đầy đủ các thông tin bắt buộc', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }

    if (password !== confirmPassword) {
      toast.warning('Mật khẩu không khớp', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }

    const vietnamesePhoneNumberPattern = /^(0[1-9][0-9]{8})$/;
    const numberPattern = /^[0-9]+$/;

    if (!vietnamesePhoneNumberPattern.test(phoneNumber)) {
      toast.warning('Số điện thoại không hợp lệ', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }

    if ((nationalId != "" && nationalId != null) && !numberPattern.test(nationalId)) {
      toast.warning('CCCD/CMT không hợp lệ', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }


    setIsLoading(true);
    const body = {
      "displayName": fullName,
      "birthDay": birthDay != null ? new Date(birthDay) : null,
      "phoneNumber": phoneNumber,
      "address": address,
      "email": email,
      "nationalId": nationalId,
      "taxCode": taxCode,
      "bankName": bankName,
      "bankAccount": bankAccount,
      "username": username,
      "password": password,
      "role": selectedRoles.join(', '),
      "status": 0
    };

    try {
      const data = await userService.createUser(body);
      if (data != null) {
        toast.success('Thành công', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
        window.location.reload();
      }
      else {
        toast.error('Có lỗi xảy ra', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
      }

    } catch (error) {
      toast.error('Có lỗi xảy ra', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
    }
    setIsLoading(false);
  }

  return (
    <Layout>
      <div className={cx("wrapper")}>
        {isLoading && (<Spinner />)}
        <div className={cx('add-form') + ' container-fluid'}>
          <form onSubmit={handleCreateUser}>
            <div class="card shadow-none border border-300" data-component-card="data-component-card">
              <div class="card-header p-4 border-bottom border-300 bg-soft">
                <div class="row g-3 justify-content-between align-items-center">
                  <div class="col-12 col-md">
                    <h5 class="text-900 mb-0" style={{ color: "rgb(75 89 109)", fontFamily: 'Inter-SemiBold', }} data-anchor="data-anchor" id="readonly-plain-text">Thêm mới cộng tác viên</h5>
                  </div>
                </div>
              </div>
              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin cá nhân</p>
              <div class="card-body p-0">
                <div class="p-4 code-to-copy">
                  <div className="row">
                    <div className='col-md-4'>
                      <label htmlFor="fullName">Họ tên <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="fullName" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="birthDay">Ngày sinh</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="date" id="birthDay" value={birthDay} onChange={(e) => setBirthDay(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="phoneNumber">Số điện thoại <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="phoneNumber" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mt-5">
                    <div className='col-md-4'>
                      <label htmlFor="email">Email <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="nationalId">CCCD/CMT</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="nationalId" value={nationalId} onChange={(e) => setNationalId(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="address">Địa chỉ</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mt-5 mb-5">
                    <div className='col-md-4'>
                      <label htmlFor="bankName">Tên ngân hàng</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="bankName" value={bankName} onChange={(e) => setBankName(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="bankAccount">STK ngân hàng</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="bankAccount" value={bankAccount} onChange={(e) => setBankAccount(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="taxCode">Mã số thuế</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="taxCode" value={taxCode} onChange={(e) => setTaxCode(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin tài khoản</p>
              <div class="card-body p-0">
                <div class="p-4 code-to-copy">
                  <div className="row">
                    <div className='col-md-4'>
                      <label htmlFor="username">Tên đăng nhập <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="password">Mật khẩu <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className='col-md-4'>
                      <label htmlFor="confirmPassword">Xác nhận mật khẩu <span className='text-danger'>*</span></label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                  </div>

                  <div className="row mt-5 mb-5">
                    <div className='col-md-8'>
                      <label className='mb-1'>Quyền <span className='text-danger'>*</span></label>
                      <br />
                      <Select
                        onChange={handleRoleSelection}
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        isMulti
                        placeholder='Lựa chọn quyền'
                        options={roleOptions}
                      />
                    </div>

                  </div>
                </div>
              </div>
              <div className='text-end me-3 mb-5'>
                <button className={cx('add-new-btn') + ' mt-2 btn btn-primary'} type="submit">Thêm mới</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default AddUser;