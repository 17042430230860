import classNames from "classnames/bind";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';

import styles from './UserList.module.scss';
import Layout from "../../components/Layout";
import { userService } from "../../services";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";

const cx = classNames.bind(styles);

const pageSize = 10;

function UserList() {
    const [userList, setUserList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [userTotal, setUserTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [roleSearch, setRoleSearch] = useState('');
    const [nameSearch, setNameSearch] = useState('');
    const [phoneNumberSearch, setPhoneNumberSearch] = useState('');
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({});

    useEffect(() => {
        setIsLoading(true);
        const fetchUsers = async () => {
            const data = await userService.getAllUser(pageNumber, pageSize);
            if (data != null) {
                var userItems = data.users.map((item, index) => ({
                    ...item,
                    order: ((pageNumber - 1) * pageSize) + index + 1,
                }));
                setUserList(userItems);
                setUserTotal(data.total);
            }
        }

        fetchUsers();
        setIsLoading(false);
    }, [pageNumber])
    
    const handlePageChange = (page) => {
        setPageNumber(page + 1);
    };

    const handleDeleteUser = async () => {
        setIsLoading(true);
        const data = await userService.deleteUser(selectedUser.id);
        if (data != null) {
            toast.success('Thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
        else {
            toast.error('Xoá thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }

    const handleSearch = async () => {
        setIsLoading(true);
        const data = await userService.searchUser(phoneNumberSearch.trim(), nameSearch.trim(), roleSearch.trim());
        if (data != null) {
            var userItems = data.users.map((item, index) => ({
                ...item,
                order: ((pageNumber - 1) * pageSize) + index + 1,
            }));
            setUserList(userItems);
            setUserTotal(data.total);
        }
        setIsLoading(false);
    };

    const getRoleString = (role) => {
        const roles = role.split(", ");
        let res = "";
        roles.forEach((value) => {
            if (value === 'Admin') res += 'Admin - ';
            else if (value === 'ApproveScan') res += 'Kiểm duyệt scan - ';
            else if (value === 'ApproveText') res += 'Kiểm duyệt text - ';
            else if (value === 'ApproveAudio') res += 'Kiểm duyệt audio - ';
            else if (value === 'Scan') res += 'Scan - ';
            else if (value === 'EditText') res += 'Thiết lập text - ';
            else if (value === 'EditAudio') res += 'Thiết lập audio - ';
        });
        return res.slice(0, -2);
    }

    const columns = [
        { field: 'order', headerName: 'STT', width: 70, align: 'center', headerClassName: 'custom-header-class' },
        { field: 'displayName', headerName: 'Họ tên', width: 200, sortable: false, },
        { field: 'email', headerName: 'Email', width: 200, sortable: false, },
        { field: 'username', headerName: 'Username', width: 150, sortable: false, },
        { field: 'phoneNumber', headerName: 'SĐT', width: 130, sortable: false, },
        {
            field: 'role', headerName: 'Quyền', width: 400,
            sortable: false,
            valueGetter: (params) => getRoleString(params.row.role),
        },
        {
            field: 'action',
            headerName: 'Thao tác',
            width: 150,
            align: 'center',
            headerClassName: 'custom-header-class',
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <div>
                    <div className="text-center">
                        <Link className="me-1" to={`/collaborator/edit/${params.row.id}`}>
                            <FontAwesomeIcon className="me-3" style={{ color: "var(--blue-color)" }} icon={faEdit} />
                        </Link>
                        <FontAwesomeIcon onClick={() => { setSelectedUser(params.row); setDeleteConfirmModal(true); }} style={{ color: "var(--red-color)", cursor: "pointer" }} icon={faTrash} />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Layout>
            <div className={cx("wrapper")}>
                {/* {isLoading && <Spinner />} */}
                {deleteConfirmModal && (
                    <Modal isOpen={true} title="Xác nhận xoá" color="red" onClose={() => { setDeleteConfirmModal(false) }} onOK={handleDeleteUser}>
                        <div className="container" style={{ width: "350px", height: "100px" }}>
                            {/* <p className="mt-0 fw-bold text-danger mb-2">Xác nhận xoá</p> */}
                            <p className="mt-3 texet-center">Bạn có chắc chắn muốn xoá <b>{selectedUser.displayName}</b> không?</p>
                        </div>
                    </Modal>
                )}
                <div className="title">
                    <p className="fw-bolder mb-4">DANH SÁCH NGƯỜI DÙNG</p>
                </div>
                <div className={cx("filter") + " row"}>
                    <div className="col-md-4">
                        <input className={cx('form-input') + " w-100"} type="text" placeholder="SĐT" value={phoneNumberSearch} onChange={(e) => setPhoneNumberSearch(e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <input className={cx('form-input') + " w-100"} type="text" placeholder="Họ Tên" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)} />

                    </div>
                    <div className="col-md-4">
                        <select value={roleSearch} onChange={(e) => setRoleSearch(e.target.value)} className={cx("form-input") + " w-100"}>
                            <option value="">Quyền</option>
                            <option value="Admin">Admin</option>
                            <option value="Scan">Scan</option>
                            <option value="EditText">Thiết lập text</option>
                            <option value="EditAudio">Thiết lập audio</option>
                            <option value="ApproveScan">Kiểm duyệt scan</option>
                            <option value="ApproveText">Kiểm duyệt text</option>
                            <option value="ApproveAudio">Kiểm duyệt audio</option>
                        </select>
                    </div>

                </div>
                <div className="action d-flex justify-content-end">
                    <button onClick={handleSearch} style={{ fontSize: "14px", height: "40px", }} className="btn btn-primary fw-bold mt-3">Tìm kiếm</button>

                </div>

                <div className="list-table mt-4">
                    <div style={{ height: 550, width: '100%', backgroundColor: "white" }}>
                        <style>
                            {`
                            .MuiDataGrid-columnHeaderTitle{
                                font-weight: bold;
                                text-align: center;
                            }
                            .custom-header-class > div > div > div{
                                margin: auto;
                            }
                            .MuiTablePagination-displayedRows {
                                margin: 0;
                            }
                            `}
                        </style>

                        <DataGrid
                            paginationMode="server"
                            loading={isLoading}
                            pageSizeOptions={[pageSize]}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: pageSize },
                                },
                            }}
                            page={pageNumber - 1}
                            rowCount={userTotal}
                            onPaginationModelChange={(params) => handlePageChange(params.page)}
                            rows={userList}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default UserList;