import classNames from "classnames/bind";
import { toast } from 'react-toastify';
import { useRef, useState, Fragment } from "react";
import { useSelector } from "react-redux";


import styles from './StepItem1.module.scss';
import RenderPDF from "../RenderPDF";
import Modal from "../Modal";
import { pageService, imageService, bookService } from "../../services";


const cx = classNames.bind(styles);

function StepItem1({ pageIndex, pdfData, url, bookId, pageId, page }) {
    const user = useSelector(state => state.auth.login?.currentUser);

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);

    const handleApprove = async () => {
        const updateWorker = await pageService.updateCurrentStatus(page.id, page.currentStatus, user.id);
        const updateApprover = await pageService.updateCurrentStatus(page.id, -2, user.id);
        if (updateWorker && updateApprover) {
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
        else {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
    }

    const openModal = () => {
        setConfirmModalOpen(true);
    }

    const closeModal = () => {
        setConfirmModalOpen(false);
    }

    const openApproveModal = () => {
        setApproveModal(true);
    }

    const closeApproveModal = () => {
        setApproveModal(false);
    }

    const openRejectModal = () => {
        setRejectModal(true);
    }

    const closeReject = () => {
        setRejectModal(false);
    }

    const handleReject = async () => {
        await pageService.rejectPage(page.id, page.currentStatus, user.id);
        await bookService.updateRejectBookStep(page.bookId, page.currentStatus);
        toast.success('Thực hiện thành công', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true
        });
        closeModal();
        window.location.reload();
        setTimeout(() => {
            page.currentStatus = page.currentStatus + 1;
        }, 2000);
    }

    const handleFileUpload = async (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = async (e) => {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
        };

        reader.readAsArrayBuffer(file);
        fileInputRef.current.value = null;
    };

    const handleAcceptSubmission = async () => {

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("bookId", page.bookId);
        formData.append("pageIndex", page.pageIndex);
        const data1 = await imageService.uploadImage(formData);
        if (data1 != null) {
            if (!page.id) {
                const pageFormData = {
                    bookId: page.bookId,
                    pageIndex: page.pageIndex,
                    currentStatus: page.currentStatus,
                    style: 0,
                };
                const result = await pageService.createPage(pageFormData);
                if (result.page != null) {
                    page.id = result.page.id;
                }
            }
            const data2 = await pageService.updateUrl1(page.id, page.pageIndex + `.${selectedFile.name.split('.').pop()}`);
            if (data2 != null) {
                await pageService.updateCurrentStatus(page.id, page.currentStatus, user.id);
                toast.success('Thực hiện thành công', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                window.location.reload();
            }
            else {
                toast.error('Thực hiện thất bại', {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                closeModal();
            }
        }
        else {
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            closeModal();
        }
    };

    return (
        <div className="wrapper">

            {confirmModalOpen && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeModal} onOK={handleAcceptSubmission}>
                    <div className="container" style={{ width: "350px" }}>
                        {page.currentStatus % 2 == 1 && (
                            <Fragment>
                                {/* <p className="mt-0 fw-bold">Xác nhận nộp</p> */}
                                <p className="mt-0">Bạn có xác nhận nộp không?</p>
                            </Fragment>
                        )}
                        {page.currentStatus % 2 == 0 && (
                            <Fragment>
                                <p className="mt-0 fw-bold">Xác nhận phê duyệt</p>
                                <p className="mt-0">Bạn có xác nhận phê duyệt không?</p>
                            </Fragment>)}
                    </div>
                </Modal>
            )}

            {approveModal && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeApproveModal} onOK={handleApprove}>
                    <div className="container" style={{ width: "350px" }}>
                        {/* <p className="mt-0 fw-bold">Xác nhận phê duyệt</p> */}
                        <p className="mt-0">Bạn có xác nhận phê duyệt không?</p>
                    </div>
                </Modal>
            )}

            {rejectModal && (
                <Modal title="Xác nhận" isOpen={true} onClose={closeReject} onOK={handleReject}>
                    <div className="container" style={{ width: "350px" }}>
                        {/* <p className="mt-0 fw-bold">Xác nhận từ chối</p> */}
                        <p className="mt-0">Bạn có xác nhận từ chối không?</p>
                    </div>
                </Modal>
            )}
            <div className={cx("page-pdf")}>
                {url && url.split(".").at(-1) !== "pdf" && page.style == 0 && <img className={cx("image")} src={process.env.REACT_APP_API_URL + `image/get-scanned/${url}`} alt="" />}
                {(!url || url.split(".").at(-1) == "pdf") && page.style == 0 && pdfData && <RenderPDF pageIndex={page.pageIndex} pdfData={pdfData} />}
                {(!url || url.split(".").at(-1) == "pdf") && page.style !== 0 && !previewUrl &&
                    (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "230px" }}>
                            <span>Trang trống</span>
                        </div>
                    )}
                {previewUrl && <img className={cx("image")} src={previewUrl} alt="" />}
            </div>
            <div className="d-flex justify-content-center mt-3">
                {page.currentStatus == 1 && (
                    <div className={cx("upload-container")}>
                        <input type="file" accept=".png, .jpg, .jpeg" id="fileInput" className={cx("file-input")} ref={fileInputRef} onChange={handleFileUpload} />
                        <label htmlFor="fileInput" className={cx("file-label")}>
                            <span className={cx("button-text") + " ms-1 btn btn-primary"}>Tải lên</span>
                        </label>
                    </div>
                )}
                {page.currentStatus == 2 && <p className={cx("save-btn") + " btn btn-outline-success"} onClick={openApproveModal}>Phê duyệt</p>}


                {page.currentStatus == 1 && <button disabled={previewUrl == null} className="btn btn-outline-secondary ms-2" onClick={openModal}>Xác nhận</button>}
                {(page.currentStatus == 2) && <p className={cx("save-btn") + " btn btn-outline-danger ms-2"} onClick={openRejectModal}>Từ chối</p>}
            </div>
        </div>
    );
}

export default StepItem1;