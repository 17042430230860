import axios from "axios";
import jwtDecode from 'jwt-decode';
import { loginSuccess, logoutFail, logoutStart, logoutSuccess } from "../redux/authSlide";
import { store } from "../redux/store";
import { dispatch } from "../redux/store";

const getUser = () => {
    const state = store.getState();
    const user = state.auth.login?.currentUser;
    return user;
};

const requestUrl = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})

const handleRefreshToken = async (user) => {
    try {
        const res = await requestUrl.post('Auth/refresh-token', { userId: user?.id }, { withCredentials: true });
        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

// let isRefreshing = false;

// let request = axios.create({
//     baseURL: process.env.REACT_APP_API_URL,
//     withCredentials: true,
// });

// request.interceptors.request.use(
//     async (config) => {
//         const user = getUser();
//         let date = new Date();
//         const decodedToken = jwtDecode(user?.accessToken);
//         if (decodedToken.exp < date.getTime() / 1000) {
//             if (!isRefreshing) {
//                 const data = await handleRefreshToken(user);
//                 if (data == null) {
//                     logoutUser(user, dispatch);
//                     return;
//                 }
//                 const refreshUser = {
//                     user: { ...user },
//                     accessToken: data.accessToken,
//                     refreshToken: data.refreshToken,
//                 };
//                 dispatch(loginSuccess(refreshUser));
//                 config.headers["Authorization"] = `Bearer ${data.accessToken}`;
//                 isRefreshing = false;
//             }
//         }
//         else {
//             config.headers["Authorization"] = `Bearer ${user.accessToken}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

let isRefreshing = false;
let refreshQueue = [];

const request = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

request.interceptors.request.use(
    async (config) => {
        const user = getUser();
        let date = new Date();
        const decodedToken = jwtDecode(user?.accessToken);

        if (decodedToken.exp < date.getTime() / 1000) {
            if (!isRefreshing) {
                isRefreshing = true;
                const data = await handleRefreshToken(user);
                isRefreshing = false;

                if (data == null) {
                    logoutUser(user, dispatch);
                    return Promise.reject("Token refresh failed");
                }

                const refreshUser = {
                    user: { ...user },
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                };
                dispatch(loginSuccess(refreshUser));

                // Retry the original request with the new token
                refreshQueue.forEach((retry) => retry(data.accessToken));
                refreshQueue = [];
            } else {
                // If refreshing, queue the request
                const retry = new Promise((resolve, reject) => {
                    refreshQueue.push((token) => {
                        config.headers["Authorization"] = `Bearer ${token}`;
                        resolve(config);
                    });
                });
                return retry;
            }
        } else {
            config.headers["Authorization"] = `Bearer ${user.accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

request.interceptors.response.use(
    (response) => {
        // If the request was successful, just return the response
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Check if the error is due to token expiration
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const user = getUser();
            try {
                // Refresh the access token
                
                const data = await handleRefreshToken(user);

                if (data == null) {
                    logoutUser(user, dispatch);
                    return Promise.reject("Token refresh failed");
                }

                // Update the user object with the new tokens
                const refreshUser = {
                    user: { ...user },
                    accessToken: data.accessToken,
                    refreshToken: data.refreshToken,
                };
                dispatch(loginSuccess(refreshUser));

                // Retry the original request with the new token
                return request(originalRequest);
            } catch (refreshError) {
                // If token refresh fails, logout the user
                logoutUser(user, dispatch);
                return Promise.reject(refreshError);
            }
        }

        // If the error is not due to token expiration, reject the request
        return Promise.reject(error);
    }
);

// request.interceptors.response.use(
//     (response) => response,
//     async (error) => {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             const user = getUser();
//             try {
                
//                 const data = await handleRefreshToken(user);
//                 if (data == null) {
//                     logoutUser(user, dispatch);
//                     return Promise.reject('Failed to refresh token');
//                 }
//                 const refreshUser = {
//                     user: { ...user },
//                     accessToken: data.accessToken,
//                     refreshToken: data.refreshToken,
//                 };
//                 dispatch(loginSuccess(refreshUser));
//                 return retryOriginalRequest(originalRequest, data.accessToken);
//             } catch (refreshError) {
//                 console.error('Error refreshing token:', refreshError);
//                 logoutUser(user, dispatch);
//                 return Promise.reject(refreshError);
//             }
//         }
//         return Promise.reject(error);
//     }
// );


const logoutUser = async (user, dispatch) => {
    const formData = {
        token: user.refreshToken,
        userId: user.id
    };
    dispatch(logoutStart());
    try {
        const res = await requestUrl.post('Auth/logout', formData);
        dispatch(logoutSuccess());
    } catch (error) {
        console.log(error);
        dispatch(logoutFail());
    }
}

export default request;