import classNames from "classnames/bind";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import { DataGrid } from '@mui/x-data-grid';


import styles from './AllBook.module.scss';
import Layout from "../../components/Layout";
import { bookService } from '../../services';
import Spinner from "../../components/Spinner";
import SmallSpinner from "../../components/SmallSpinner";

const cx = classNames.bind(styles);

const pageSize = 10;

function AllBook() {
    const [bookList, setBookList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [bookTotal, setBookTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [statusSearch, setStatusSearch] = useState('');
    const [titleSearch, setTitleSearch] = useState('');
    const [libCodeSearch, setLibCodeSearch] = useState('');
    const [bookDetailOpen, setBookDetailOpen] = useState(false);
    const [selectedBook, setSelectedBook] = useState({});
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [isSmallLoading, setIsSmallLoading] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false);

    const domain = process.env.REACT_APP_API_URL;
    const imgUrl = domain + "image/";
    

    useEffect(() => {
        setIsDataLoading(true);
        const fetchBooks = async () => {
            const data = await bookService.getAllBook(pageNumber, pageSize);
            if (data !== null) {
                var bookItems = data.books.map((item, index) => ({
                    ...item,
                    order: ((pageNumber - 1) * pageSize) + index + 1,
                }));
                setBookList(bookItems);
                setBookTotal(data.total);
            }
        }

        fetchBooks();
        setIsDataLoading(false);
    }, [pageNumber])


    const handlePageChange = async (page) => {
        setPageNumber(page + 1);
    }

    const handleSearch = async () => {
        setIsLoading(true);
        const data = await bookService.searchBook(libCodeSearch.trim(), titleSearch.trim(), statusSearch.trim());
        if (data !== null) {
            var bookItems = data.books.map((item, index) => ({
                ...item,
                order: ((pageNumber - 1) * pageSize) + index + 1,
            }));
            setBookList(bookItems);
            setBookTotal(data.total);
        }
        setIsLoading(false);
    };

    const openBookDetail = () => {
        setBookDetailOpen(true);
    }

    const closeBookDetail = () => {
        setBookDetailOpen(false);
    }

    const handleBookItemClick = (params) => {
        if (params.field === 'action') return;
        openBookDetail();
        setSelectedBook(params.row);
    }

    const handlePublishPage = async (type) => {
        // setIsSmallLoading(true);
        const bookId = selectedBook.id;
        const response = await bookService.publishBook(bookId, type);
        if (response.success) {
            toast.success('Thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        else {
            toast.error(response.data, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        // setIsSmallLoading(false);
    }

    const handleDeleteBook = async () => {
        setIsLoading(true);
        const data = await bookService.deleteBook(selectedBook.id);
        if (data !== null) {
            toast.success('Thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
            window.location.reload();
        }
        else {
            toast.error('Xoá thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }

    const getBookStatus = (book) => {
        if (book.step3.totalPage !== 0 && book.step3.pageDone === book.step3.totalPage) {
            return 'Đã thiết lập bản text'
        }
        else if (book.step1.totalPage !== 0 && book.step1.pageDone === book.step1.totalPage) {
            return 'Đã scan'
        }
        else if (book.step1.pageDone !== book.step1.totalPage) {
            return 'Chưa scan'
        }


    }

    const columns = [
        { field: 'order', headerName: 'STT', width: 70, align: 'center', headerClassName: 'custom-header-class' },
        { field: 'libCode', headerName: 'Mã sách', width: 90, sortable: false, },
        { field: 'title', headerName: 'Tiêu đề', width: 300, sortable: false, },
        { field: 'publisherName', headerName: 'NXB', width: 120, sortable: false, },
        { field: 'author', headerName: 'Tác giả', width: 200, sortable: false, },
        { field: 'genre', headerName: 'Thể loại', width: 160, sortable: false, },
        {
            field: 'publishYear',
            headerName: 'Năm XB',
            sortable: false,
            align: 'center',
            width: 90,
        },
        {
            field: 'totalPage',
            headerName: 'Số trang',
            sortable: false,
            align: 'center',
            width: 90,
        },
        {
            field: 'status', headerName: 'Trạng thái', width: 100,
            sortable: false,
            valueGetter: (params) => getBookStatus(params.row),
        },
        {
            field: 'action',
            headerName: 'Thao tác',
            width: 150,
            sortable: false,
            disableClickEventBubbling: true,
            renderCell: (params) => (
                <div>
                    <div className="text-center">
                        <Link to={`/book/edit/${params.row.id}`}>
                            <FontAwesomeIcon className="me-3" style={{ color: "var(--blue-color)" }} icon={faEdit} />
                        </Link>
                        <FontAwesomeIcon onClick={() => { setSelectedBook(params.row); setDeleteConfirmModal(true); }} style={{ color: "var(--red-color)" }} icon={faTrash} />
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Layout>
            <div className={cx("wrapper")}>
                {isLoading && <Spinner />}
                {bookDetailOpen && (
                    <Modal width="50%" title="Thông tin sách" color="black" isOpen={true} onClose={closeBookDetail} OKButton={false}>
                        <div className="row" style={{ padding: "20px" }}>
                            <div className="col-3">
                                <div className="img text-center">
                                    {selectedBook.bookCoverUrl && <img style={{ borderRadius: "6px" }} className="w-100" src={imgUrl + selectedBook.bookCoverUrl} alt="Book Cover" />}
                                    {!selectedBook.bookCoverUrl && <img style={{ borderRadius: "6px" }} className="w-100" src={imgUrl + "Shared/empty.png"} alt="Book Cover" />}
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="">
                                    <h5 style={{ fontFamily: "Inter-ExtraBold", color: "#2b2b2b" }} className="fw-bolder mb-0">{selectedBook.title}</h5>
                                    {selectedBook.subTitle && <p className="fst-italic m-0 text-secondary" style={{ fontSize: "15px" }}>({selectedBook.subTitle})</p>}
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Mã sách: {selectedBook.libCode}</p>
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Tác giả: {selectedBook.author}</p>
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">NXB: {selectedBook.publisherName}</p>
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Thể loại: {selectedBook.genre}</p>
                                    {selectedBook.translator && <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Dịch giả: {selectedBook.translator}</p>}
                                    {selectedBook.volume && <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Tập: {selectedBook.volume}</p>}
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Lần tái bản: {selectedBook.reIssue}</p>
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Năm xuất bản: {selectedBook.publishYear}</p>
                                    <p style={{ fontSize: "14px" }} className="text-secondary m-0 mb-1 mt-2">Số trang: {selectedBook.totalPage}</p>
                                </div>
                                <hr />

                                <div className="action">
                                    {selectedBook.step1.url && <a target="_blank" rel="noreferrer" href={`${process.env.REACT_APP_API_URL}pdf/${selectedBook.id}/${selectedBook.step1.url}`} className="btn btn-light me-2">Xem PDF</a>}
                                    {selectedBook.step1.pageDone === selectedBook.step1.totalPage && <span onClick={() => handlePublishPage("pdf")} className="btn btn-primary me-2">
                                        Xuất bản
                                        {isSmallLoading && <SmallSpinner />}
                                    </span>}
                                    {selectedBook.step3.pageDone === selectedBook.step3.totalPage && <a href={`${domain}/book/download/${selectedBook.id}?type=word`} className="btn btn-primary me-2">
                                        Tải xuống nội dung
                                        {/* {isSmallLoading && <SmallSpinner />} */}
                                    </a>}
                                    <Link to={`/book/edit/${selectedBook.id}`}><span className="btn btn-success me-2">Cập nhật</span></Link>
                                    <span onClick={() => setDeleteConfirmModal(true)} className="btn btn-danger me-2">Xoá</span>
                                </div>
                            </div>
                        </div>

                    </Modal>
                )}

                {deleteConfirmModal && (
                    <Modal isOpen={true} title="Xác nhận xoá" color="red" onClose={() => { setDeleteConfirmModal(false) }} onOK={handleDeleteBook}>
                        <div className="container" style={{ width: "350px", height: "100px" }}>
                            {/* <p className="mt-0 fw-bold text-danger mb-2">Xác nhận xoá</p> */}
                            <p className="mt-3">Bạn có chắc chắn muốn xoá sách <b>{selectedBook.title}</b> không?</p>
                        </div>
                    </Modal>
                )}
                <div className="title">
                    <p className="fw-bolder mb-4">DANH SÁCH SÁCH</p>
                </div>
                <div className={cx("filter") + " row"}>
                    <div className="col-md-4">
                        <input className={cx('form-input') + " w-100"} type="text" placeholder="Mã sách" value={libCodeSearch} onChange={(e) => setLibCodeSearch(e.target.value)} />
                    </div>
                    <div className="col-md-4">
                        <input className={cx('form-input') + " w-100"} type="text" placeholder="Tiêu đề" value={titleSearch} onChange={(e) => setTitleSearch(e.target.value)} />

                    </div>
                    <div className="col-md-4">
                        <select value={statusSearch} onChange={(e) => setStatusSearch(e.target.value)} className={cx("form-input") + " w-100"}>
                            <option value="">Trạng thái</option>
                            <option value="1">Chưa scan</option>
                            <option value="2">Đã scan</option>
                            <option value="3">Đã thiết lập bản text</option>
                        </select>
                    </div>

                </div>
                <div className="action d-flex justify-content-end">
                    <button onClick={handleSearch} style={{ fontSize: "14px", height: "40px", }} className="btn btn-primary fw-bold mt-3">Tìm kiếm</button>

                </div>
                {/* Table display */}

                <div className="list-table mt-4 bg-white">
                    <div style={{ height: 550, width: '100%' }}>
                        <style>
                            {`
                            .MuiDataGrid-columnHeaderTitle{
                                font-weight: bold;
                                text-align: center;
                            }
                            .custom-header-class > div > div > div{
                                margin: auto;
                            }
                            .MuiTablePagination-displayedRows {
                                margin: 0;
                            }
                            `}
                        </style>

                        <DataGrid
                            paginationMode="server"
                            loading={isDataLoading}
                            pageSizeOptions={[pageSize]}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: pageSize },
                                }
                            }}
                            onCellClick={(params) => handleBookItemClick(params)}
                            page={pageNumber - 1}
                            rowCount={bookTotal}
                            onPaginationModelChange={(params) => handlePageChange(params.page)}
                            rows={bookList}
                            columns={columns}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default AllBook;