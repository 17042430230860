import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import styles from './SmallSpinner.module.scss';

const cx = classNames.bind(styles);

function SmallSpinner() {
    return (
        <span className={cx("loadingCircle") + ""}>
            <FontAwesomeIcon icon={faSpinner} className={cx("spinnerIcon")} />
        </span>
    );
}

export default SmallSpinner;