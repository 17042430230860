import classNames from "classnames/bind";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import styles from './InfoBox.module.scss';


const cx = classNames.bind(styles);

function InfoBox({ label, number, icon, color }) {
    const iconStyle = {
        color,
    };

    const borderStyle = {
        border: "3px solid " + color,
    }

    return (
        <div className={cx("wrapper") + " d-lfex"}>
            <div className="info">
                <p className="fw-bolder m-0 p-0 fs-5">{number}</p>
                <p className={cx("label") + " p-0 m-0"}>{label}</p>
            </div>
            <div className={cx("icon")} style={borderStyle}>
                <FontAwesomeIcon className="d-block" style={iconStyle} icon={icon} />
            </div>

        </div>
    );
}

export default InfoBox;