import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import styles from './AccountInfo.module.scss';
import Spinner from "../Spinner";
import { userService } from "../../services";
import { updateUserState } from "../../api/request";

const cx = classNames.bind(styles);

function AccountInfo() {
    const user = useSelector(state => state.auth.login?.currentUser);
    const [email, setEmail] = useState("");
    const [username, setUsername] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(user){
            setEmail(user.email);
            setUsername(user.username);
        }
    },[user])


    const handleUpdate = async () => {
        setIsLoading(true);
        const formData = {
            email: email,
            username: username,
        };

        const data = await userService.updateUserInfo(user.id, formData);
        if(data != null){
            updateUserState(data);
            toast.success('Thực hiện thành công', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        else{
            toast.error('Thực hiện thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
            });
        }
        setIsLoading(false);
    }


    return ( 
        <div className="wrapper container mt-5" style={{backgroundColor: "white", width: "80%", padding: "50px 0", borderRadius: "6px"}}>
            {isLoading && <Spinner/>}
            <div className={cx('add-form') + ' container-fluid w-50'}>
                <form>
                    <div className="row">
                        <div className='col-12'>
                            <label htmlFor="email">Email</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                        <div className='col-12 mt-4'>
                            <label htmlFor="username">Tên đăng nhập</label>
                            <br />
                            <input className={cx('form-input') + ' w-100'} type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>
                    </div>
                    <p className={cx('add-new-btn') + ' mt-5 btn btn-primary'} onClick={handleUpdate}>Cập nhật</p>
                </form>
            </div>
        </div>
     );
}

export default AccountInfo;