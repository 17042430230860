import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload, faUpload, faX } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames/bind';
import { read, utils } from 'xlsx';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreatableSelect from 'react-select/creatable';

import styles from "./AddBook.module.scss";
import Layout from '../../components/Layout';
import { bookService, imageService, externalService } from '../../services';
import Modal from '../../components/Modal';
import Spinner from '../../components/Spinner';

const cx = classNames.bind(styles);

function AddBook() {
  const [bookList, setBookList] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [libCode, setLibCode] = useState('');
  const [title, setTitle] = useState('');
  const [totalPage, setTotalPage] = useState(0);
  const [subTitle, setSubTitle] = useState('');
  const [publishYear, setPublishYear] = useState(0);
  const [publisher, setPublisher] = useState('');
  const [reissue, setReissue] = useState(0);
  const [volume, setVolume] = useState(null);
  const [genre, setGenre] = useState('');
  const [author, setAuthor] = useState('');
  const [translator, setTranslator] = useState('');
  const [bookCoverUrl, setBookCoverUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [authorOptions, setAuthorOptions] = useState([]);
  const [publisherOptions, setPublisherOptions] = useState([]);


  const imgUrl = process.env.REACT_APP_API_URL + "image/";
  const fileInputRef = useRef(null);
  const imageFileInputRef = useRef(null);


  useEffect(() => {
    const getAuthors = async () => {
      const data = await externalService.getAuthors();
      if (data != null) {
        const options = data.map(item => ({
          label: item.name,
          value: item.name
        }));
        setAuthorOptions(options);
      }
    }

    const getPublishers = async () => {
      const data = await externalService.getPublishers();
      if (data != null) {
        const options = data.map(item => ({
          label: item.name,
          value: item.name
        }));
        setPublisherOptions(options);
      }
    }

    getAuthors();
    getPublishers();
  },[]);

  const handleFileUpload = (event) => {

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });
      console.log(workbook);
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      // console.log(worksheet);
      const jsonData = utils.sheet_to_json(worksheet, { header: 0 });
      // console.log(jsonData);
      setBookList(jsonData);
      openModal();
    };

    reader.readAsArrayBuffer(file);
    fileInputRef.current.value = null;
  };


  function getFormattedCurrentDateTime() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }

  const handleImageFileUpload = (event) => {

    const file = event.target.files[0];
    const reader = new FileReader();


    reader.onload = (e) => {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      const formattedDateTime = getFormattedCurrentDateTime();
      const extension = file.name.split('.').pop();
      const result = formattedDateTime + '.' + extension;
      setBookCoverUrl(result);
    };

    reader.readAsArrayBuffer(file);
    imageFileInputRef.current.value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.trim() === "" || libCode.trim() === "" || publisher.trim() === "" || author.trim() === ""
      || genre.trim() === "") {
      toast.warning('Vui lòng nhập đầy đủ các thông tin bắt buộc', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }

    if (totalPage <= 0) {
      toast.warning('Số trang không hợp lệ', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
      return;
    }
    setIsLoading(true);

    const formData = {
      title: title,
      subTitle: subTitle,
      libCode: libCode,
      totalPage: totalPage,
      publisherName: publisher,
      author: author,
      translator: translator,
      volume: volume,
      publishYear: publishYear,
      genre: genre,
      bookCoverUrl: bookCoverUrl,
      reIssue: reissue,
    };

    try {
      const data = await bookService.createBook(formData);
      if (data.book != null) {
        if (selectedFile != null) {
          const imgFormData = {
            file: selectedFile,
            fileName: bookCoverUrl
          };

          try {
            const uploadData = await imageService.uploadBookCover(data.book.id, imgFormData);
            if (uploadData.message != "Successfully") {
              toast.error('Thêm ảnh bìa thất bại', {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                draggable: true
              });
            }
          } catch (error) {
            toast.error('Thêm ảnh bìa thất bại', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              draggable: true
            });
          }
        }

        toast.success('Thêm mới sách thành công', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });

        setLibCode('');
        setTitle('');
        setSubTitle('');
        setPublisher('');
        setAuthor('');
        setTranslator('');
        setGenre('');
        setVolume(null);
        setReissue(0);
        setPublishYear(0);
        setTotalPage(0);
        setPreviewUrl('');
        setReissue(0);
      }
      else {
        toast.error('Thêm mới sách thất bại', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
      }
    } catch (error) {
      toast.error('Thêm mới sách thất bại', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
    }
    setIsLoading(false);

  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const onOK = async () => {
    try {
      const list = bookListMapper(bookList);
      const data = await bookService.addBookList(list);
      if (data != null) {
        toast.success('Thêm mới sách thành công', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
      }

      else {
        toast.error('Thêm mới sách thất bại', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true
        });
      }
    } catch (error) {
      toast.error('Thêm mới sách thất bại', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true
      });
    }
    closeModal();
  }

  const handleRemoveItem = (index) => {
    setBookList((bookList) => {
      const updatedBookList = [...bookList];
      updatedBookList.splice(index, 1);
      return updatedBookList;
    });
  };

  const bookListMapper = (bookList) => {
    var list = [];
    bookList.forEach(book => {
      list.push({
        title: book['Tiêu đề'],
        subTitle: book['Tiêu đề phụ'],
        libCode: book['Mã sách'],
        totalPage: book['Số trang'],
        publisherName: book['NXB'],
        author: book['Tác giả'],
        translator: book['Dịch giả'],
        volume: book['Tập'],
        publishYear: book['Năm xuất bản'],
        genre: book['Thể loại'],
        reissue: book['Lần tái bản'],
      })
    });
    return list;
  };

  const handleChange = (selectedOption) => {
    // setSelectedOption(selectedOption);
    console.log(selectedOption.value);
  };

  const handleDownload = () => {
    // Define the path to your Excel file in the public folder
    const filePath = '/template/DanhSachSach.xlsx';
    // Create a virtual anchor element to trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = filePath;
    downloadLink.download = 'DanhSachSach.xlsx';

    // Append the anchor element to the document and trigger the click event
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the anchor element from the document
    document.body.removeChild(downloadLink);
  };


  return (
    <Layout>
      <div className={cx("wrapper")}>
        {isLoading && <Spinner />}
        <div>
          {modalOpen && (
            <Modal isOpen={modalOpen} onClose={closeModal} onOK={onOK}>
              <p className='text-center fw-bolder'>DANH SÁCH SÁCH TẢI LÊN</p>
              <div className="card" style={{ borderRadius: ".5rem", maxHeight: "500px", overflowY: "auto" }}>
                <table className={cx("main-table") + " table table-borderless mb-0"}>
                  <thead>
                    <tr>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "60px" }} scope="col">STT</th>
                      <th className={cx("table-header")} style={{ width: "100px" }} scope="col">Mã sách</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">Tiêu đề</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">Tiêu đề phụ</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">NXB</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">Tác giả</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">Dịch giả</th>
                      <th className={cx("table-header")} style={{ width: "350px" }} scope="col">Thể loại</th>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "350px" }} scope="col">Tập</th>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "350px" }} scope="col">Lần tái bản</th>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "350px" }} scope="col">Năm xuất bản</th>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "50px" }} scope="col">Số trang</th>
                      <th className={cx("table-header") + ' text-center'} style={{ width: "100px" }} scope="col">Thao tác</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookList.map((book, index) => (
                      <tr key={index}>
                        <td className={cx("table-data") + ' text-center'}>{index + 1}</td>
                        <td className={cx("table-data")}>{book['Mã sách']}</td>
                        <td className={cx("table-data")}>{book['Tiêu đề']}</td>
                        <td className={cx("table-data")}>{book['Tiêu đề phụ']}</td>
                        <td className={cx("table-data")}>{book['NXB']}</td>
                        <td className={cx("table-data")}>{book['Tác giả']}</td>
                        <td className={cx("table-data")}>{book['Dịch giả']}</td>
                        <td className={cx("table-data")}>{book['Thể loại']}</td>
                        <td className={cx("table-data") + ' text-center'}>{book['Tập']}</td>
                        <td className={cx("table-data") + ' text-center'}>{book['Lần tái bản']}</td>
                        <td className={cx("table-data") + ' text-center'}>{book['Năm xuất bản']}</td>
                        <td className={cx("table-data") + ' text-center'}>{book['Số trang']}</td>
                        <td className={cx("table-data") + ' text-center'} onClick={() => handleRemoveItem(index)} style={{ cursor: "pointer", fontSize: "12px" }}>
                          <FontAwesomeIcon className='text-danger' icon={faX} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Modal>
          )}

        </div>
        <div className={cx('add-form') + ' container-fluid'}>
          <form onSubmit={handleSubmit}>
            <div className="card shadow-none border border-300 my-4" data-component-card="data-component-card">
              <div className="card-header p-4 border-bottom border-300 bg-soft">
                <div className="row g-3 justify-content-between align-items-center">
                  <div className="col-6 col-md p-0">
                    <h5 className="text-900 mb-0" style={{ color: "rgb(75 89 109)", fontFamily: 'Inter-SemiBold', }} data-anchor="data-anchor" id="readonly-plain-text">Thêm mới sách</h5>
                  </div>

                  <div className={cx("upload-container") + " text-end col-6"}>

                    <input type="file" accept=".xlsx, .xls" id="fileInput" className={cx("file-input")} ref={fileInputRef} onChange={handleFileUpload} />
                    <label htmlFor="fileInput" className={cx("file-label")}>
                      <FontAwesomeIcon icon={faUpload} />
                      <span className={cx("button-text") + " ms-1"} style={{ fontSize: "16px" }}>Tải lên</span>
                    </label>
                    <p onClick={handleDownload} className='btn btn-secondary m-0 ms-2'>
                      <FontAwesomeIcon icon={faDownload} />
                      Tệp mẫu</p>
                  </div>

                </div>

              </div>


              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin chung</p>
              <div className="card-body p-0">
                <div className="p-4 code-to-copy">
                  <div className="row">
                    <div className={cx("upload-container") + ' col-2'}>
                      <label htmlFor="imageFile">Ảnh bìa <span style={{ color: "red" }}>*</span></label>
                      <br />
                      <div className="img">
                        {previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={previewUrl} alt="Book Cover" />}
                        {!previewUrl && <img style={{ borderRadius: "6px", height: "200px", width: "auto" }} className="w-auto" src={imgUrl + "Shared/empty.png"} alt="Book Cover" />}
                        <br />
                      </div>
                      <input className={cx("file-input")} ref={imageFileInputRef} type="file" id="imageFile" accept="image/*" onChange={handleImageFileUpload} />
                      <label htmlFor="imageFile" className={cx("file-label1") + ' mt-3'}>
                        <p className="btn btn-outline-primary text-center " type="button" style={{ backgroundColor: "white", color: "blue", border: "blue solid 1px", borderRadius: "18px", fontSize: "14px" }}>Tải lên</p>
                      </label>
                    </div>

                    <div className="col-10">
                      <div className="row">
                        <div className='col-md-3'>
                          <label htmlFor="libCode">Mã sách <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="bookCode" value={libCode} onChange={(e) => setLibCode(e.target.value)} />
                        </div>
                        <div className='col-md-9'>
                          <label htmlFor="title">Tiêu đề <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-6">
                          <label htmlFor="publisher">Nhà xuất bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <CreatableSelect placeholder={"Nhà xuất bản..."}  onChange={(selectedOption) => {setPublisher(selectedOption?.value);}} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={publisherOptions} />
                          {/* <input className={cx('form-input') + ' w-100'} type="text" id="publisher" value={publisher} onChange={(e) => setPublisher(e.target.value)} /> */}
                        </div>
                        <div className="col-6">
                          <label htmlFor="author">Tác giả <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <CreatableSelect isMulti placeholder={"Tác giả..."} onChange={(selectedOption) => { setAuthor(selectedOption.map(item => item.label).join(', ')); }} formatCreateLabel={(inputValue) => `Thêm mới "${inputValue}"`} isClearable options={authorOptions} />
                          {/* <input className={cx('form-input') + ' w-100'} type="text" id="author" value={author} onChange={(e) => setAuthor(e.target.value)} /> */}
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className='col-3'>
                          <label htmlFor="publishYear">Năm xuất bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="publishYear" value={publishYear} onChange={(e) => setPublishYear(e.target.value)} />
                        </div>
                        <div className='col-3'>
                          <label htmlFor="reissue">Lần tái bản <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="reissue" value={reissue} onChange={(e) => setReissue(e.target.value)} />
                        </div>
                        <div className="col-6">
                          <label htmlFor="genre">Thể loại <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="text" id="genre" value={genre} onChange={(e) => setGenre(e.target.value)} />
                        </div>
                      </div>

                      <div className="row mt-5 mb-5">
                        <div className='col-3'>
                          <label htmlFor="totalPage">Số trang <span style={{ color: "red" }}>*</span></label>
                          <br />
                          <input className={cx('form-input') + ' w-100'} type="number" id="totalPage" value={totalPage} onChange={(e) => setTotalPage(e.target.value)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <p className="ms-3 mt-5" style={{ fontFamily: "Inter-Medium", color: "rgb(87, 84, 84)", fontSize: "18px" }} data-anchor="data-anchor" id="readonly-plain-text">Thông tin bổ sung</p>
              <div className="card-body p-0">
                <div className="ps-4 pe-4 code-to-copy">
                  <div className="row mt-4">
                    <div className="col-6">
                      <label htmlFor="transalator">Dịch giả</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="transalator" value={translator} onChange={(e) => setTranslator(e.target.value)} />
                    </div>
                    <div className="col-6">
                      <label htmlFor="subTitle">Tiêu đề phụ</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="text" id="subTitle" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} />
                    </div>
                  </div>
                  <div className="row mt-4 mb-5">
                    <div className="col-6">
                      <label htmlFor="volume">Tập</label>
                      <br />
                      <input className={cx('form-input') + ' w-100'} type="number" id="volume" value={volume} onChange={(e) => setVolume(e.target.value)} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-end me-3 mb-5'>
                <button className={cx('add-new-btn') + ' mt-3 mb-3 btn btn-primary'} type="submit">Thêm mới</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  );
}

export default AddBook;