import React, { useState } from "react";
import classNames from "classnames/bind";

import styles from './Tab.module.scss';

const cx = classNames.bind(styles);

const Tab = ({items, defaultActiveKey, left, center}) => {
    const [activeTab, setActiveTab] = useState(defaultActiveKey);

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    return (
        <div className={cx("tab-container")}>
            <div className={cx("tab-buttons") + " d-flex justify-content-around mt-3 w-75"}>
                {items.map((item, index) => (
                    <p
                    className={cx("tab-button", { active: activeTab === item.key })}
                    onClick={() => handleTabClick(item.key)}
                >
                    {item.label}
                </p>
                ))}
            </div>

            <div className={cx("tab-content")}>
                {items.map((item, index) => (
                    activeTab === index+1 && item.children
                ))}
            </div>
        </div>
    );
};

export default Tab;
