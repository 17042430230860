import classNames from "classnames/bind";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";


import styles from './CommonSettings.module.scss';
import { jobService } from "../../services";
import Modal from "../../components/Modal";
import Layout from "../../components/Layout";


const cx = classNames.bind(styles);

function CommonSettings() {
    const [jobList, setJobList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [price, setPrice] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const data = await jobService.getAllJob();
            if (data.jobs != null) {
                setJobList(data.jobs);
            }
        }

        fetchData();
    }, []);

    const closeModal = () => {
        setOpenModal(false);
    }

    const handleUpdateJob = async () => {
        if (price === 0) closeModal();
        else {
            const formData = {
                price: price
            };
            selectedJob.price = price;
            const data = await jobService.updateJob(selectedJob.id, formData);
            if (data != null) {
                toast.success("Cập nhật thành công", {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
                window.location.reload();
            }
            else {
                toast.error("Cập nhật thất bại", {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true
                });
            }
        }

    }

    const openModalFunc = (job) => {
        setSelectedJob(job);
        setOpenModal(true);
    };



    return <Layout>
        <div className={cx("wrapper")}>
            {openModal && <Modal title="Cập nhật giá" isOpen={true} onClose={closeModal} onOK={handleUpdateJob}>
                <div className="ms-3 me-3">
                    <p className="fw-bold" style={{ fontSize: "14px" }}>Cập nhật mức giá {selectedJob.name}</p>
                    <div className="mb-3" style={{ width: "300px" }}>
                        <label className="me-3" htmlFor="price">Giá:</label>
                        <input onChange={(e) => setPrice(e.target.value)} type="number" id="price" />
                    </div>
                </div>
            </Modal>}

            <div class="card shadow-none border border-300 my-4" data-component-card="data-component-card">
                <div class="card-header p-4 border-bottom border-300 bg-soft">
                    <div class="row g-3 justify-content-between align-items-center">
                        <div class="col-12 col-md">
                            <h5 class="text-900 mb-0" style={{ color: "rgb(75 89 109)", fontFamily: 'Inter-SemiBold', }} data-anchor="data-anchor" id="readonly-plain-text">Thiết lập giá</h5>
                        </div>
                    </div>
                </div>
                <div class="card-body p-0">
                    <div class="p-4 code-to-copy">
                        <div className="pt-3 pb-2" style={{ width: "60%", backgroundColor: "white" }}>
                            {jobList?.map((job, index) => (
                                <div key={index} className="row mb-3">
                                    <div className="col ms-5">{job.name}</div>
                                    <div className="col text-center">{job.price.toLocaleString()}</div>
                                    <div className="col text-center">
                                        <p onClick={() => openModalFunc(job)} className={cx("update-price-btn") + " text-center text-primary"}>Cập nhật giá</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>;
}

export default CommonSettings;